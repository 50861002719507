import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getClusterCveCountsQueryRunner = (apiUrl: string, clusterID: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'A',
        url: apiUrl,
        root_selector: 'data.k8sCluster.totalCveCounts',
        url_options: {
          body_graphql_query: `
              query getClusterCveCounts($id: ID!) {
                k8sCluster(id: $id) {
                  id
                  totalCveCounts {
                    critical
                    high
                    medium
                    low
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            id: clusterID,
          }),
        },
      },
    ])
  );
