import { TypedDocumentNode, gql } from '@apollo/client';
import { GetK8sClusterHeaderQuery, GetK8sClusterHeaderQueryVariables } from '__generated__/graphql';

export const GET_K8S_CLUSTER_HEADER: TypedDocumentNode<GetK8sClusterHeaderQuery, GetK8sClusterHeaderQueryVariables> =
  gql`
    query getK8sClusterHeader($id: ID!) {
      k8sCluster(id: $id) {
        id
        name
        namespaces {
          versions {
            id
          }
        }
      }
    }
  `;
