import {
  EmbeddedScene,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { RadioToggle } from 'scenes/controls/RadioToggle';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { SOURCES_ROUTE } from 'shared/constants';

import { getCveSplitLayout, getPackageSplitLayout } from './layouts';
import { getCveTotalsOutOfSloStat, getCveTotalsStat } from './panels';
import { getCvesWithSloQueryRunner } from './queryRunners/getCvesWithSloQueryRunner';

interface GetVulnerabilitiesTabType {
  apiUrl: string;
  sourceID: string;
  versionID: string;
}

export const getVulnerabilitiesTab = ({ apiUrl, sourceID, versionID }: GetVulnerabilitiesTabType) => {
  const cveVariable = new TextInputVariable({
    name: 'cve',
    prefix: <Icon name="search" />,
    placeholder: 'Search CVEs',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const packageVariable = new TextInputVariable({
    name: 'pkg',
    prefix: <Icon name="search" />,
    placeholder: 'Search Packages',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const cveSplitLayout = getCveSplitLayout(apiUrl, versionID);
  const packageSplitLayout = getPackageSplitLayout(apiUrl, versionID);

  const layout = new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        width: '100%',
        minHeight: '500px',
        key: 'splitScene',
        body: cveSplitLayout,
      }),
    ],
  });

  const viewToggle = new RadioToggle({
    options: [
      { value: 'cve', label: 'By CVE' },
      { value: 'package', label: 'By Package' },
    ],
    value: 'cve',
    onChange: (value) => {
      if (value === 'cve') {
        layout.setState({ children: [new SceneFlexItem({ body: cveSplitLayout })] });
      } else {
        layout.setState({ children: [new SceneFlexItem({ body: packageSplitLayout })] });
      }
    },
  });

  return new SceneAppPage({
    title: 'Vulnerabilities',
    titleIcon: 'bug',
    key: 'vulnerabilitiesTab',
    url: `${SOURCES_ROUTE}/${sourceID}/version/${versionID}/vulnerabilities`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'vulnerabilities',
        $variables: new SceneVariableSet({
          variables: [cveVariable, packageVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'row',
              width: '100%',
              height: '110px',
              $data: getCvesWithSloQueryRunner(apiUrl, versionID),
              children: [
                new SceneFlexItem({
                  width: '50%',
                  body: getCveTotalsStat(),
                }),
                new SceneFlexItem({
                  width: '50%',
                  body: getCveTotalsOutOfSloStat(),
                }),
              ],
            }),
            layout,
          ],
        }),
        controls: [new VariableValueSelectors({}), new SceneControlsSpacer(), viewToggle],
      });
    },
  });
};
