import { Card, Stack } from '@grafana/ui';
import {
  BUG_BOUNTY_ROUTE,
  CVES_ROUTE,
  K8S_SCANNING_ROUTE,
  PLAN_RELEASE_ROUTE,
  SECRETS_ROUTE,
  SOURCES_ROUTE,
} from 'shared/constants';

export const AppMenu = () => {
  return (
    <Stack direction="row" justifyContent="space-evenly" gap={2} width="100%">
      <Card href={SOURCES_ROUTE}>
        <Card.Heading>Explore Sources</Card.Heading>
        <Card.Description>Everything we scan</Card.Description>
      </Card>
      <Card href={CVES_ROUTE}>
        <Card.Heading>Explore CVEs</Card.Heading>
        <Card.Description>Everything we&apos;ve found</Card.Description>
      </Card>
      <Card href={K8S_SCANNING_ROUTE}>
        <Card.Heading>Kubernetes</Card.Heading>
        <Card.Description>Internal infrastructure scans</Card.Description>
      </Card>
      <Card href={PLAN_RELEASE_ROUTE}>
        <Card.Heading>Plan a Release</Card.Heading>
        <Card.Description>Prepare for a security release</Card.Description>
      </Card>
      <Card href={BUG_BOUNTY_ROUTE}>
        <Card.Heading>Bug Bounty</Card.Heading>
        <Card.Description>First party vulnerabilities</Card.Description>
      </Card>
      <Card href={SECRETS_ROUTE}>
        <Card.Heading>Secrets</Card.Heading>
        <Card.Description>All exposed secrets</Card.Description>
      </Card>
    </Stack>
  );
};
