import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { TableCellDisplayMode } from '@grafana/schema';
import { ColorBoxCell } from 'scenes/components/ColorBoxCell';
import { ActionsCell } from 'scenes/components/IssuesTable';
import { PackageCell } from 'scenes/components/PackageCell';
import { RISK_LEVEL_MAPPINGS, SLO_REMAINING_THRESHOLDS, TOOL_MAPPINGS } from 'scenes/panelConstants';

export const getRelatedIssuesTable = (cve: string) =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'filterByValue',
            options: {
              filters: [
                {
                  fieldName: 'cve',
                  config: {
                    id: 'equal',
                    options: {
                      value: cve,
                    },
                  },
                },
              ],
              type: 'include',
              match: 'any',
            },
          },
          {
            id: 'organize',
            options: {
              excludeByName: {
                cve: true,
                cveId: true,
                cvssScore: true,
                severity: true,
              },
              indexByName: {
                package: 1,
                installedVersion: 2,
                fixedVersion: 3,
                target: 4,
                risk: 5,
                sloRemaining: 6,
                toolName: 7,
                id: 8,
              },
              renameByName: {
                id: 'Actions',
                package: 'Package',
                installedVersion: 'Installed',
                fixedVersion: 'Fixed In',
                target: 'Target',
                sloRemaining: 'SLO Remaining',
                risk: 'Risk',
                toolName: 'Detected By',
              },
            },
          },
          {
            id: 'sortBy',
            options: {
              sort: [
                {
                  field: 'SLO Remaining',
                },
              ],
            },
          },
        ],
      })
    )
    .setTitle(`Risks related to ${cve}`)
    .setThresholds(SLO_REMAINING_THRESHOLDS)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('Package').overrideCustomFieldConfig('cellOptions', {
        // @ts-ignore
        type: 'custom',
        cellComponent: PackageCell,
      });

      overridesBuilder.matchFieldsWithName('Installed').overrideCustomFieldConfig('align', 'center');

      overridesBuilder.matchFieldsWithName('Fixed In').overrideCustomFieldConfig('align', 'center');

      overridesBuilder
        .matchFieldsWithName('Risk')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('width', 116)
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ColorBoxCell,
        })
        .overrideMappings(RISK_LEVEL_MAPPINGS);

      overridesBuilder
        .matchFieldsWithName('toolName')
        .overrideCustomFieldConfig('align', 'center')
        .overrideMappings(TOOL_MAPPINGS);

      overridesBuilder
        .matchFieldsWithName('SLO Remaining')
        .overrideCustomFieldConfig('width', 120)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideUnit('days');

      overridesBuilder
        .matchFieldsWithName('Actions')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ActionsCell,
        })
        .overrideCustomFieldConfig('width', 100);

      overridesBuilder.matchFieldsWithName('isDismissed').overrideCustomFieldConfig('hidden', true);
    })
    .build();
