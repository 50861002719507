import { TypedDocumentNode, gql } from '@apollo/client';
import { GetVersionNameQuery, GetVersionNameQueryVariables } from '__generated__/graphql';

export const GET_VERSION_NAME: TypedDocumentNode<GetVersionNameQuery, GetVersionNameQueryVariables> = gql`
  query getVersionName($id: ID!) {
    version(id: $id) {
      id
      version
      latestScans {
        id
        ingestTime
        tool {
          name
        }
      }
      source {
        id
        name
        origin
      }
      k8sNamespaces {
        cluster {
          id
          name
        }
      }
    }
  }
`;
