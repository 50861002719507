import { SelectableValue } from '@grafana/data';
import { Issue, Source, Version } from '__generated__/graphql';

import { looseSemverSort } from '../utils';

import { ExceptionFormType, TargetsType } from './CreateException';

export const parseSourceOptionsFromIssues = (issues: Issue[], targets: TargetsType) => {
  const uniqueSources = issues.reduce((acc, issue) => {
    if (!issue.isDismissed && !issue.version.isArchived) {
      if (!acc.find((src) => src.id === issue.version.source.id)) {
        acc.push(issue.version.source);
      }
    }
    return acc;
  }, [] as Source[]);

  const options = uniqueSources.map((src) => ({
    label: src.name,
    value: src.id,
    icon: src.type === 'repository' ? 'github' : 'docker',
  })) as SelectableValue[];

  const seenSources = targets.map((target) => target.source?.value).filter(Boolean);
  return options.filter((option) => !seenSources.includes(option.value));
};

export const parseVersionOptionsFromIssues = (issues: Issue[], sourceID: number) => {
  const uniqueVersions = issues.reduce((acc, issue) => {
    // @ts-ignore
    if (!issue.isDismissed && !issue.version.isArchived && issue.version.source.id === sourceID) {
      if (!acc.find((v) => v.id === issue.version.id)) {
        acc.push(issue.version);
      }
    }
    return acc;
  }, [] as Version[]);

  return looseSemverSort(uniqueVersions).map((v) => ({
    label: v.version,
    value: v.id,
  })) as SelectableValue[];
};

export const buildAddExceptionPayload = (formData: ExceptionFormType) => ({
  cveId: formData.cve?.value,
  reason: formData.category,
  team: formData.team,
  description: formData.description,
  targets: formData.targets.map(({ source, versions, all }) => ({
    sourceId: source!.value,
    versionIds: versions.map((version) => version.value),
    all,
  })),
});
