import { Field, Label, Stack, Tooltip, Text, Icon, TagsInput } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { RepositoryScanningConfig } from '../types';

interface ContainerImagesFieldType<T extends RepositoryScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const ContainerImagesField = <T extends RepositoryScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: ContainerImagesFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Container Images{required ? '*' : ''}</span>
            <Tooltip placement="right-end" content={<Text element="p">Container images generated by the repo</Text>}>
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'containerImages' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <TagsInput
            placeholder="Add image (enter key to add)"
            tags={field.value as string[]}
            onChange={(tags) => field.onChange(tags)}
            width={40}
            disabled={disabled}
            invalid={!!formState.errors.containerImages}
          />
        )}
      />
    </Field>
  );
};
