import { CollapsableSection, ErrorBoundary, Alert, LoadingPlaceholder, Divider, Box } from '@grafana/ui';
import { Suspense, useState } from 'react';

import { DeleteSource } from './DeleteSource';
import { ManageAlerting } from './ManageAlerting';
import { ManageGroups } from './ManageGroups';
import { ManageScanning } from './ManageScanning';
import { ManageVersions } from './ManageVersions';

interface ManageSourceType {
  sourceID: string;
}

export const ManageSource = ({ sourceID }: ManageSourceType) => {
  const [isGeneralOpen, setIsGeneralOpen] = useState<boolean>(true);
  const [isScanningOpen, setIsScanningOpen] = useState<boolean>(false);
  const [isAlertingOpen, setIsAlertingOpen] = useState<boolean>(false);
  const [isVersionsOpen, setIsVersionsOpen] = useState<boolean>(false);
  const [isDangerZoneOpen, setIsDangerZoneOpen] = useState<boolean>(false);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load source settings"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Box maxWidth="480px">
            <CollapsableSection label="General" isOpen={isGeneralOpen} onToggle={setIsGeneralOpen}>
              <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                <ManageGroups sourceID={sourceID} />
              </Suspense>
            </CollapsableSection>
            <Divider />
            <CollapsableSection label="Scanning" isOpen={isScanningOpen} onToggle={setIsScanningOpen}>
              <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                <ManageScanning sourceID={sourceID} />
              </Suspense>
            </CollapsableSection>
            <Divider />
            <CollapsableSection label="Alerting" isOpen={isAlertingOpen} onToggle={setIsAlertingOpen}>
              <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                <ManageAlerting sourceID={sourceID} />
              </Suspense>
            </CollapsableSection>
            <Divider />
            <CollapsableSection label="Versions" isOpen={isVersionsOpen} onToggle={setIsVersionsOpen}>
              <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                <ManageVersions sourceID={sourceID} />
              </Suspense>
            </CollapsableSection>
            <Divider />
            <CollapsableSection label="Danger Zone" isOpen={isDangerZoneOpen} onToggle={setIsDangerZoneOpen}>
              <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                <DeleteSource sourceID={sourceID} />
              </Suspense>
            </CollapsableSection>
          </Box>
        );
      }}
    </ErrorBoundary>
  );
};
