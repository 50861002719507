import { getAppEvents } from '@grafana/runtime';
import {
  CustomVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon, Text, TextLink } from '@grafana/ui';
import { BugBountySortOrder } from '__generated__/graphql';
import { ShowBasedOnConditionBehavior } from 'scenes/behaviors/ShowBasedOnConditionBehavior';
import { BUG_BOUNTY_SQUADS } from 'scenes/components/ExploreBugBountySubmissions/constants';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { BugBountySubmissions } from 'scenes/customScenes/BugBountySubmissions';
import { BUG_BOUNTY_ROUTE, BUG_BOUNTY_REPORT_ROUTE } from 'shared/constants';
import { BugBountySquadUpdatedEvent } from 'shared/events';

import { getBugBountyReportDrilldown } from './getBugBountyReportDrilldown';
import { getBugBountyPayoutStat, getBugBountyPayoutsByTeamStat } from './panels';
import { getBugBountySubmissionsQueryRunner } from './queryRunners';

interface GetBugBountyPageType {
  apiUrl: string;
}

export const getBugBountyPage = ({ apiUrl }: GetBugBountyPageType) => {
  const payoutsQueryRunner = getBugBountySubmissionsQueryRunner(apiUrl);
  getAppEvents().subscribe(BugBountySquadUpdatedEvent, () => {
    payoutsQueryRunner.runQueries();
  });

  return new SceneAppPage({
    title: 'Bug Bounty',
    subTitle: (
      <Text>
        Read more about submitting a bug bounty report{' '}
        <TextLink external href="https://app.intigriti.com/programs/grafanalabs/grafanaossbbp/detail">
          here
        </TextLink>
      </Text>
    ),
    key: 'bugBountyPage',
    url: BUG_BOUNTY_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'bugBounty',
        $timeRange: new SceneTimeRange({
          from: 'now/y',
          to: 'now/y',
        }),
        $variables: new SceneVariableSet({
          variables: [
            new TextInputVariable({
              name: 'submissionCode',
              prefix: <Icon name="search" />,
              placeholder: 'Search submission code',
              width: 30,
              hide: VariableHide.hideLabel,
            }),
            new CustomVariable({
              key: 'squad',
              name: 'squad',
              label: 'Squad',
              value: 'All',
              text: 'All',
              query: `All,${Object.values(BUG_BOUNTY_SQUADS).join(',')}`,
            }),
            new CustomVariable({
              name: 'severity',
              label: 'Severity',
              value: 'All',
              text: 'All',
              query: `All,Exceptional,Critical,High,Medium,Low,Undecided`,
            }),
            new CustomVariable({
              name: 'status',
              label: 'Status',
              query: `Accepted,Pending,Triage,Closed,Archived`,
              isMulti: true,
              includeAll: true,
              allValue: 'All',
              defaultToAll: true,
            }),
            new CustomVariable({
              name: 'sortBy',
              label: 'Sort By',
              value: '',
              query: `Created On : ${BugBountySortOrder.DateCreatedDesc},Reward : ${BugBountySortOrder.RewardDesc}`,
              includeAll: false,
            }),
          ],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'row',
              height: '150px',
              $data: payoutsQueryRunner,
              children: [
                new SceneFlexItem({
                  $behaviors: [
                    new ShowBasedOnConditionBehavior({
                      references: ['squad'],
                      condition: (squad: CustomVariable) => squad.state.value === 'All',
                    }),
                  ],
                  height: '100%',
                  width: '180px',
                  body: getBugBountyPayoutStat(),
                }),
                new SceneFlexItem({
                  height: '100%',
                  body: getBugBountyPayoutsByTeamStat(),
                }),
              ],
            }),
            new SceneFlexItem({
              height: '100%',
              body: new BugBountySubmissions({
                submissionCode: '${submissionCode}',
                squad: '${squad}',
                severity: '${severity}',
                status: '${status}',
                sortBy: '${sortBy}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({}), new SceneControlsSpacer(), new SceneTimePicker({})],
      });
    },
    drilldowns: [
      {
        routePath: `${BUG_BOUNTY_REPORT_ROUTE}/:submissionCode`,
        getPage(routeMatch, parent) {
          const submissionCode = routeMatch.params.submissionCode;
          return getBugBountyReportDrilldown({ submissionCode, parent });
        },
      },
    ],
  });
};
