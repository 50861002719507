import { Box, CollapsableSection, RadioButtonGroup, Text } from '@grafana/ui';
import { useState } from 'react';

import { ConfigureContainer } from './ConfigureContainer';
import { ConfigureRepository } from './ConfigureRepository';

const CONTAINER = 'container';
const REPOSITORY = 'repository';

export const ConfigureSource = () => {
  const [isProjectTypeOpen, setIsProjectTypeOpen] = useState<boolean>(true);
  const [type, setType] = useState<string>();

  return (
    <>
      <Text element="h2">Configure a New Source</Text>
      <Box maxWidth="480px">
        <CollapsableSection label="1. Select Project Type" isOpen={isProjectTypeOpen} onToggle={setIsProjectTypeOpen}>
          <RadioButtonGroup
            options={[
              { label: 'Container', value: CONTAINER },
              { label: 'Repository', value: REPOSITORY },
            ]}
            value={type}
            onChange={(type) => {
              setType(type);
              setIsProjectTypeOpen(false);
            }}
            fullWidth={false}
          />
        </CollapsableSection>
        {type === CONTAINER && <ConfigureContainer />}
        {type === REPOSITORY && <ConfigureRepository />}
      </Box>
    </>
  );
};
