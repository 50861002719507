import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getTotalCveCountsQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'C',
        url: apiUrl,
        root_selector: 'data.summary.totalCveCounts',
        url_options: {
          body_graphql_query: `
            query getSummary($filters: SummaryFilters) {
              summary(filters: $filters) {
                totalCveCounts {
                  critical
                  high
                  medium
                  low
                }
              }
            }
          `,
          body_graphql_variables: JSON.stringify({
            filters: {
              groupId: '$group',
              latestOnly: '$latestOnly',
              fromConfigScanOnly: '$fromConfigScanOnly',
            },
          }),
        },
      },
    ])
  );
