import { DataLinkClickEvent } from '@grafana/data';
import {
  CustomVariable,
  EmbeddedScene,
  PanelBuilders,
  SceneAppPage,
  SceneAppPageLike,
  SceneCanvasText,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneReactObject,
  SceneVariableSet,
  SplitLayout,
  VariableValueSelectors,
  sceneGraph,
} from '@grafana/scenes';
import { BarGaugeDisplayMode, BarGaugeValueMode, TableCellDisplayMode, VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { RiskLevel } from '__generated__/graphql';
import { ColorBoxCell } from 'scenes/components/ColorBoxCell';
import { TriggerScanButton } from 'scenes/components/TriggerScan';
import { VersionHeader } from 'scenes/components/VersionHeader';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { Secrets } from 'scenes/customScenes/Secrets';
import { CVSS_SCORE_THRESHOLDS, SLO_REMAINING_THRESHOLDS } from 'scenes/panelConstants';
import { SOURCES_ROUTE } from 'shared/constants';

import { getSecretTotalsStat } from '../about/panels/getSecretTotalsStat';
import { getTotalSecretsCountsQueryRunner } from '../about/queryRunners/getTotalSecretCountsQueryRunner';

import { getCveTotalsOutOfSloStat, getCveTotalsStat, getRelatedIssuesTable } from './panels';
import { getCvesWithSloQueryRunner } from './queryRunners/getCvesWithSloQueryRunner';

interface GetVersionDrilldownType {
  apiUrl: string;
  sourceID: string;
  versionID: string;
  parent: SceneAppPageLike;
}

const NO_CVE = 'No CVE';

export const getVersionDrilldown = ({ apiUrl, sourceID, versionID, parent }: GetVersionDrilldownType) => {
  const cveUrlParam = new URLSearchParams(window.location.search).get('var-cve');

  const splitter = new SplitLayout({
    direction: 'row',
    initialSize: 0.35,
    primary: new SceneFlexItem({
      // This table references the splitter scene object and
      // can't be trivially pulled out to its own function
      body: PanelBuilders.table()
        .setData(
          new SceneDataTransformer({
            transformations: [
              {
                id: 'groupBy',
                options: {
                  fields: {
                    cve: {
                      operation: 'groupby',
                    },
                    sloRemaining: {
                      aggregations: ['min'],
                      operation: 'aggregate',
                    },
                    cvssScore: {
                      operation: 'groupby',
                    },
                  },
                },
              },
              {
                id: 'organize',
                options: {
                  indexByName: {
                    cve: 0,
                    'sloRemaining (min)': 1,
                    cvssScore: 2,
                  },
                  renameByName: {
                    cve: 'CVE',
                    cvssScore: 'CVSS',
                    'sloRemaining (min)': 'SLO Remaining',
                  },
                },
              },
              {
                id: 'sortBy',
                options: {
                  sort: [
                    {
                      field: 'SLO Remaining',
                      desc: false,
                    },
                  ],
                },
              },
            ],
          })
        )
        .setTitle('Detected CVEs')
        .setCustomFieldConfig('align', 'left')
        .setThresholds(SLO_REMAINING_THRESHOLDS)
        .setNoValue('No CVEs')
        .setMin(0)
        .setMax(60)
        .setOverrides((overridesBuilder) => {
          overridesBuilder
            .matchFieldsWithName('CVE')
            .overrideCustomFieldConfig('filterable', true)
            .overrideCustomFieldConfig('width', 200)
            .overrideNoValue(NO_CVE)
            .overrideLinks([
              {
                title: 'See risks for ${__data.fields.CVE}',
                url: '',
                onClick: (e: DataLinkClickEvent) => {
                  let cve = e.origin.field.values.get(e.origin.rowIndex);
                  if (cve === '') {
                    cve = NO_CVE;
                  }

                  const cveVariable = sceneGraph.lookupVariable('cve', splitter) as CustomVariable;
                  cveVariable.setState({ value: cve });

                  splitter.setState({
                    secondary: new SceneFlexItem({
                      minWidth: 500,
                      body: getRelatedIssuesTable(cve),
                    }),
                  });
                },
              },
            ]);
          overridesBuilder
            .matchFieldsWithName('SLO Remaining')
            .overrideCustomFieldConfig('cellOptions', {
              type: TableCellDisplayMode.Gauge,
              mode: BarGaugeDisplayMode.Basic,
              valueDisplayMode: BarGaugeValueMode.Text,
            })
            .overrideUnit('days');
          overridesBuilder
            .matchFieldsWithName('CVSS')
            .overrideCustomFieldConfig('align', 'center')
            .overrideCustomFieldConfig('width', 80)
            .overrideDecimals(1)
            .overrideNoValue('0.0')
            .overrideCustomFieldConfig('cellOptions', {
              // @ts-ignore
              type: 'custom',
              cellComponent: ColorBoxCell,
            })
            .overrideThresholds(CVSS_SCORE_THRESHOLDS);
        })
        .build(),
      minWidth: 300,
    }),
    secondary: new SceneFlexItem({
      minWidth: 500,
      body:
        cveUrlParam && cveUrlParam.length
          ? new SceneFlexLayout({
              children: [
                new SceneFlexItem({
                  minWidth: 500,
                  body: getRelatedIssuesTable(cveUrlParam),
                }),
              ],
            })
          : new SceneCanvasText({
              text: 'Select a CVE to see risks',
              fontSize: 20,
              align: 'center',
            }),
    }),
  });

  return new SceneAppPage({
    title: 'Version',
    renderTitle: () => VersionHeader({ versionID }),
    key: 'versionDrilldown',
    url: `${SOURCES_ROUTE}/${sourceID}/version/${versionID}`,
    controls: [
      new SceneReactObject({
        component: () => TriggerScanButton({ versionID }),
      }),
    ],
    getParentPage: () => parent,
    getScene: () => {
      return new EmbeddedScene({
        key: 'version',
        $variables: new SceneVariableSet({
          variables: [
            new CustomVariable({
              name: 'cve',
              hide: VariableHide.hideVariable,
            }),
            new TextInputVariable({
              name: 'package',
              prefix: <Icon name="gf-layout-simple" />,
              placeholder: 'Search Packages',
              width: 40,
              hide: VariableHide.hideLabel,
            }),
            new CustomVariable({
              name: 'riskLevel',
              label: 'Risk Level',
              description: 'Risk is an internal measure used for generating SLOs. It is not the same is severity.',
              value: '',
              query: `Maximum : ${RiskLevel.Maximum},Severe : ${RiskLevel.Severe},Significant : ${RiskLevel.Significant},Moderate : ${RiskLevel.Moderate},Minor : ${RiskLevel.Minor},None : ${RiskLevel.None}`,
              includeAll: true,
              allValue: RiskLevel.All,
              defaultToAll: true,
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
        body: new SceneFlexLayout({
          direction: 'column',
          $data: getCvesWithSloQueryRunner(apiUrl, versionID),
          children: [
            new SceneFlexLayout({
              direction: 'row',
              width: '100%',
              height: '110px',
              children: [
                new SceneFlexItem({
                  width: '50%',
                  body: getCveTotalsStat(),
                }),
                new SceneFlexItem({
                  width: '50%',
                  body: getCveTotalsOutOfSloStat(),
                }),
                new SceneFlexItem({
                  width: '15%',
                  $data: getTotalSecretsCountsQueryRunner(apiUrl, versionID),
                  body: getSecretTotalsStat(versionID),
                }),
              ],
            }),
            new SceneFlexItem({
              width: '100%',
              minHeight: '500px',
              key: 'cveSplitScene',
              body: splitter,
            }),
            new SceneFlexLayout({
              direction: 'column',
              children: [
                new SceneReactObject({
                  component: () => 'Exposed Secrets',
                }),
                new SceneFlexItem({
                  body: new Secrets({
                    versionId: versionID,
                    category: '',
                    severity: '',
                    target: '',
                    type: 'ALL',
                    secretFirst: '',
                    secretAfter: '',
                    simplified: true,
                  }),
                }),
              ],
            }),
          ],
        }),
      });
    },
  });
};
