import { TypedDocumentNode, gql } from '@apollo/client';
import {
  AddGroupMutation,
  AddGroupMutationVariables,
  ArchiveVersionMutation,
  ArchiveVersionMutationVariables,
  DeleteSourceMutation,
  DeleteSourceMutationVariables,
  DeleteVersionMutation,
  DeleteVersionMutationVariables,
  ModifyAlertingConfigMutation,
  ModifyAlertingConfigMutationVariables,
  ModifyContainerSourceConfigMutation,
  ModifyContainerSourceConfigMutationVariables,
  ModifyRepositorySourceConfigMutation,
  ModifyRepositorySourceConfigMutationVariables,
  RemoveGroupMutation,
  RemoveGroupMutationVariables,
  UnarchiveVersionMutation,
  UnarchiveVersionMutationVariables,
} from '__generated__/graphql';

export const ADD_GROUP: TypedDocumentNode<AddGroupMutation, AddGroupMutationVariables> = gql`
  mutation addGroup($input: AddGroupInput!) {
    addGroup(input: $input) {
      id
      name
    }
  }
`;

export const REMOVE_GROUP: TypedDocumentNode<RemoveGroupMutation, RemoveGroupMutationVariables> = gql`
  mutation removeGroup($input: RemoveGroupInput!) {
    removeGroup(input: $input)
  }
`;

export const DELETE_VERSION: TypedDocumentNode<DeleteVersionMutation, DeleteVersionMutationVariables> = gql`
  mutation deleteVersion($input: DeleteVersionInput!) {
    deleteVersion(input: $input)
  }
`;

export const DELETE_SOURCE: TypedDocumentNode<DeleteSourceMutation, DeleteSourceMutationVariables> = gql`
  mutation deleteSource($input: DeleteSourceInput!) {
    deleteSource(input: $input)
  }
`;

export const ARCHIVE_VERSION: TypedDocumentNode<ArchiveVersionMutation, ArchiveVersionMutationVariables> = gql`
  mutation archiveVersion($input: ArchiveVersionInput!) {
    archiveVersion(input: $input)
  }
`;

export const UNARCHIVE_VERSION: TypedDocumentNode<UnarchiveVersionMutation, UnarchiveVersionMutationVariables> = gql`
  mutation unarchiveVersion($input: ArchiveVersionInput!) {
    unarchiveVersion(input: $input)
  }
`;

export const MODIFY_ALERTING_CONFIG: TypedDocumentNode<
  ModifyAlertingConfigMutation,
  ModifyAlertingConfigMutationVariables
> = gql`
  mutation modifyAlertingConfig($input: ModifyAlertingConfigInput!) {
    modifyAlertingConfig(input: $input) {
      id
      sourceId
      slackAlert {
        id
        enabled
        channelName
        severities
      }
      githubIssue {
        id
        enabled
        repositoryName
        createSubIssues
        labels
        assignees
      }
    }
  }
`;

export const MODIFY_CONTAINER_SOURCE_CONFIG: TypedDocumentNode<
  ModifyContainerSourceConfigMutation,
  ModifyContainerSourceConfigMutationVariables
> = gql`
  mutation modifyContainerSourceConfig($input: ModifyContainerSourceConfigInput!) {
    modifyContainerSourceConfig(input: $input) {
      id
    }
  }
`;

export const MODIFY_REPOSITORY_SOURCE_CONFIG: TypedDocumentNode<
  ModifyRepositorySourceConfigMutation,
  ModifyRepositorySourceConfigMutationVariables
> = gql`
  mutation modifyRepositorySourceConfig($input: ModifyRepositorySourceConfigInput!) {
    modifyRepositorySourceConfig(input: $input) {
      id
    }
  }
`;
