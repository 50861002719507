import { useMutation } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Box, Button } from '@grafana/ui';
import {
  ModifyRepositorySourceConfigInput,
  ModifyRepositorySourceConfigMutation,
  RepositorySourceConfig,
  Source,
} from '__generated__/graphql';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  AutoArchiveField,
  ContainerImagesField,
  ExcludedPathsField,
  LastTagsToScanField,
  RepositoryNameField,
  ScanMainField,
  TagPrefixField,
} from '../ConfigureSource/Fields';
import { RepositoryScanningConfig, SharedScanningConfig } from '../ConfigureSource/types';

import { MODIFY_REPOSITORY_SOURCE_CONFIG } from './ManageSourceMutations';

export interface ManageRepositoryForm extends RepositoryScanningConfig, SharedScanningConfig {}

interface ManageRepositoryScanningType {
  source: Source;
}

export const ManageRepositoryScanning = ({ source }: ManageRepositoryScanningType) => {
  const [modifyRepositorySourceConfig] = useMutation<ModifyRepositorySourceConfigMutation>(
    MODIFY_REPOSITORY_SOURCE_CONFIG,
    {
      ignoreResults: true,
      onError: () => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to update scanning config`],
        });
      },
      onCompleted: () => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: [`Success: updated scanning config`],
        });
      },
    }
  );

  const config = source.config as RepositorySourceConfig;

  const { control, handleSubmit, formState } = useForm<ManageRepositoryForm>({
    defaultValues: {
      repositoryName: config.repositoryName,
      autoArchive: config.autoArchive,
      tagPrefix: config.tagPrefix || '',
      containerImages: config.containerImages || [],
      lastTagsToScan: config.lastTagsToScan,
      excludedPaths: config.excludedPaths || [],
      scanMain: config.scanMain,
    },
  });

  const onSubmit: SubmitHandler<ManageRepositoryForm> = (data) => {
    const payload: ModifyRepositorySourceConfigInput = {
      sourceId: source.id,
      repositoryName: data.repositoryName,
      autoArchive: data.autoArchive,
      tagPrefix: data.tagPrefix,
      containerImages: data.containerImages,
      lastTagsToScan: data.lastTagsToScan,
      excludedPaths: data.excludedPaths,
      scanMain: data.scanMain,
    };

    modifyRepositorySourceConfig({
      variables: {
        input: payload,
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RepositoryNameField control={control} formState={formState} required disabled />
        <ScanMainField control={control} formState={formState} />
        <TagPrefixField control={control} formState={formState} />
        <LastTagsToScanField control={control} formState={formState} />
        <ExcludedPathsField control={control} formState={formState} />
        <ContainerImagesField control={control} formState={formState} />
        <AutoArchiveField control={control} formState={formState} />
        <Box marginTop={1}>
          <Button
            type="submit"
            size="md"
            disabled={!formState.isDirty}
            tooltip={!formState.isDirty ? 'No changes' : ''}
          >
            Save Scanning Configuration
          </Button>
        </Box>
      </form>
    </>
  );
};
