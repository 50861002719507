import { useSuspenseQuery } from '@apollo/client';
import { Stack, EmptySearchResult } from '@grafana/ui';
import type { Cve, GetCvesQuery } from '__generated__/graphql';

import { CveCard } from './CveCard';
import { GET_CVES } from './CveCardQueries';

interface CveCardGridType {
  cve: string;
  package: string;
  sortBy: string;
  first: string;
  after: string;
}

export const CveCardGrid = ({ cve, package: pkg, sortBy, first, after }: CveCardGridType) => {
  const { data } = useSuspenseQuery<GetCvesQuery>(GET_CVES, {
    variables: {
      filters: {
        ...(cve.length ? { cve: cve } : {}),
        ...(pkg.length ? { package: pkg } : {}),
        sortBy,
      },
      first,
      after,
    },
  });

  const cves = data.cves.response as Cve[];

  return (
    <Stack direction="column">
      {cves.length ? (
        cves.map((cve, index) => <CveCard cve={cve} key={index} />)
      ) : (
        <EmptySearchResult>No CVEs were found.</EmptySearchResult>
      )}
    </Stack>
  );
};
