import { Control, FormState } from 'react-hook-form';

import { RepositorySourceFormType } from './ConfigureRepository';
import {
  AutoArchiveField,
  ContainerImagesField,
  ExcludedPathsField,
  LastTagsToScanField,
  RepositoryNameField,
  ScanMainField,
  TagPrefixField,
} from './Fields';

interface ConfigureRepositoryScansType {
  control: Control<RepositorySourceFormType>;
  formState: FormState<RepositorySourceFormType>;
}

export const ConfigureRepositoryScans = ({ control, formState }: ConfigureRepositoryScansType) => {
  return (
    <>
      <RepositoryNameField control={control} formState={formState} prefix="grafana/" required />
      <ScanMainField control={control} formState={formState} />
      <TagPrefixField control={control} formState={formState} />
      <LastTagsToScanField control={control} formState={formState} />
      <ExcludedPathsField control={control} formState={formState} />
      <ContainerImagesField control={control} formState={formState} />
      <AutoArchiveField control={control} formState={formState} />
    </>
  );
};
