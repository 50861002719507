import { getAppEvents } from '@grafana/runtime';
import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
  SceneFlexItem,
  CustomVariable,
  sceneGraph,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { SourceType } from '__generated__/graphql';
import { ActWhenVariablesChanged } from 'scenes/controls/ActWhenVariablesChanged';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { Paginator } from 'scenes/customScenes/Paginator';
import { Secrets } from 'scenes/customScenes/Secrets';
import { SECRET_CATEGORIES, SECRET_SEVERITIES, SECRETS_ROUTE } from 'shared/constants';
import { SecretCardTagClickedEvent } from 'shared/events';

import { getSecretsCountQueryRunner } from './queryRunners';

interface GetSecretsScanningPageType {
  apiUrl: string;
}

const createOptions = (optionsObj: Record<string, string>) =>
  Object.entries(optionsObj)
    .map((obj) => `${obj[1]} : ${obj[0].length ? obj[0] : ' '}`)
    .join(',');

export const getSecretsScanningPage = ({ apiUrl }: GetSecretsScanningPageType) => {
  const severityVariable = new CustomVariable({
    name: 'severity',
    label: 'Severity',
    value: '',
    query: createOptions(SECRET_SEVERITIES),
  });

  const targetVariable = new TextInputVariable({
    name: 'target',
    prefix: <Icon name="search" />,
    placeholder: 'Search Targets',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const categoryVariable = new CustomVariable({
    name: 'category',
    label: 'Category',
    value: '',
    query: createOptions(SECRET_CATEGORIES),
  });

  const versionVariable = new CustomVariable({
    name: 'versionId',
    label: 'Version',
    value: 0,
    hide: VariableHide.hideVariable,
    query: '0',
  });

  const typeVariable = new CustomVariable({
    name: 'type',
    label: 'Type',
    value: '',
    query: createOptions(Object.fromEntries(Object.entries(SourceType).map(([key, value]) => [value, key]))),
  });

  getAppEvents().subscribe(SecretCardTagClickedEvent, ({ payload }) => {
    const { severityValue, severityLabel, versionID } = payload;
    if (severityValue && severityValue.length) {
      severityVariable.changeValueTo(severityValue, severityLabel);
    } else if (versionID && versionID.length) {
      versionVariable.changeValueTo(versionID);
      severityVariable.changeValueTo('', 'All');
      categoryVariable.changeValueTo('', 'All');
      typeVariable.changeValueTo('ALL', 'All');
    }
  });

  return new SceneAppPage({
    title: 'Secrets Scanning',
    subTitle: 'Browse exposed secrets in projects and images',
    key: 'secretsPage',
    url: SECRETS_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'secrets',
        $variables: new SceneVariableSet({
          variables: [
            targetVariable,
            categoryVariable,
            severityVariable,
            versionVariable,
            typeVariable,
            new CustomVariable({
              name: 'secretFirst',
              value: 12,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '12',
            }),
            new CustomVariable({
              name: 'secretAfter',
              value: 0,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '0',
            }),
          ],
        }),
        $behaviors: [
          new ActWhenVariablesChanged({
            variableNames: ['versionId', 'category', 'severity', 'target', 'type'],
            onChange: (variable) => {
              const after = sceneGraph.lookupVariable('secretAfter', variable) as CustomVariable;
              after.setState({ value: 0 });
            },
          }),
          new ActWhenVariablesChanged({
            variableNames: ['category', 'severity', 'target', 'type'],
            onChange: (variable) => {
              const after = sceneGraph.lookupVariable('versionId', variable) as CustomVariable;
              after.setState({ value: 0, query: '0' });
            },
          }),
        ],
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              height: '100%',
              body: new Secrets({
                versionId: '${versionId}',
                category: '${category}',
                severity: '${severity}',
                type: '${type}',
                target: '${target}',
                secretFirst: '${secretFirst}',
                secretAfter: '${secretAfter}',
                simplified: false,
              }),
            }),
            new SceneFlexItem({
              $data: getSecretsCountQueryRunner(apiUrl),
              body: new Paginator({
                first: '${secretFirst}',
                after: '${secretAfter}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
  });
};
