import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { LicenseGridContainer } from 'scenes/components/License';

interface LicensesState extends SceneObjectState {
  versionId?: string;
  pkg?: string;
  category?: string;
  licenseFirst?: string;
  licenseAfter?: string;
}

export class Licenses extends SceneObjectBase<LicensesState> {
  static Component = LicensesRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['versionId', 'pkg', 'category', 'licenseFirst', 'licenseAfter'],
  });

  public constructor(state: LicensesState) {
    super({
      versionId: '',
      pkg: '',
      category: '',
      licenseFirst: '',
      licenseAfter: '',
      ...state,
    });
  }
}

function LicensesRenderer({ model }: SceneComponentProps<Licenses>) {
  const { versionId, pkg, category, licenseFirst, licenseAfter } = model.useState();

  return (
    <LicenseGridContainer
      versionId={sceneGraph.interpolate(model, versionId)}
      pkg={sceneGraph.interpolate(model, pkg)}
      category={sceneGraph.interpolate(model, category)}
      first={sceneGraph.interpolate(model, licenseFirst)}
      after={sceneGraph.interpolate(model, licenseAfter)}
    />
  );
}
