import { useSuspenseQuery } from '@apollo/client';
import { Stack, EmptySearchResult } from '@grafana/ui';
import type { GetSourcesQuery, Source } from '__generated__/graphql';

import { SourceCard } from './SourceCard';
import { GET_SOURCES } from './SourceCardQueries';

interface SourceCardGridType {
  source: string;
  type: string;
  group: string;
  first: string;
  after: string;
}

export const SourceCardGrid = ({ source, type, group, first, after }: SourceCardGridType) => {
  const { data } = useSuspenseQuery<GetSourcesQuery>(GET_SOURCES, {
    variables: {
      filters: {
        type,
        ...(source.length ? { name: source } : {}),
        ...(group.length ? { groupId: group } : {}),
      },
      first,
      after,
    },
    fetchPolicy: 'cache-and-network',
  });

  const sources = data.sources.response as Source[];

  return (
    <Stack direction="column">
      {sources.length ? (
        sources.map((source, index) => <SourceCard source={source} key={index} />)
      ) : (
        <EmptySearchResult>No sources were found.</EmptySearchResult>
      )}
    </Stack>
  );
};
