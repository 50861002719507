import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useDeferredValue } from 'react';

import { LicenseGrid } from './LicenseGrid';

interface LicenseGridContainerType {
  versionId: string;
  pkg: string;
  first: string;
  after: string;
  category: string;
}

export const LicenseGridContainer = ({ versionId, pkg, category, first, after }: LicenseGridContainerType) => {
  const deferredVersionId = useDeferredValue(versionId);
  const deferredPkg = useDeferredValue(pkg);
  const deferredCategory = useDeferredValue(category);
  const deferredFirst = useDeferredValue(first);
  const deferredAfter = useDeferredValue(after);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load licenses"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <LicenseGrid
              versionId={deferredVersionId}
              pkg={deferredPkg}
              category={deferredCategory}
              first={deferredFirst}
              after={deferredAfter}
            />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
