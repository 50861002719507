import { Field, Label, Stack, Tooltip, Text, Icon, Switch } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { SharedScanningConfig } from '../types';

interface AutoArchiveFieldType<T extends SharedScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const AutoArchiveField = <T extends SharedScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: AutoArchiveFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Auto-Archive{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={
                <Text element="p">
                  Automatically archive versions that are no longer scanned. May take up to one hour to take effect.
                </Text>
              }
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={`autoArchive` as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Switch
            {...field}
            value={field.value as boolean}
            disabled={disabled}
            invalid={!!formState.errors.autoArchive}
          />
        )}
      />
    </Field>
  );
};
