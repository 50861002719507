import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { AppRootProps } from '@grafana/data';
import { useMemo } from 'react';
import { App as ScenesApp } from 'scenes/app';
import { GQL_URL } from 'shared/constants';
import { PluginPropsContext } from 'utils/utils.plugin';

export const App = (props: AppRootProps) => {
  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: GQL_URL,
    });

    return new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache({
        typePolicies: {
          BugBountySubmission: {
            // SubmissionCode acts as an ID for a BugBountySubmission
            keyFields: ['submissionCode'],
          },
          RepositorySourceConfig: {
            fields: {
              scanConfigs: {
                merge(existing = [], incoming: any[]) {
                  return [...incoming];
                },
              },
            },
          },
        },
      }),
    });
  }, []);

  return (
    <PluginPropsContext.Provider value={props}>
      <ApolloProvider client={client}>
        <ScenesApp />
      </ApolloProvider>
    </PluginPropsContext.Provider>
  );
};
