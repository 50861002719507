import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { Stack, useStyles2, Pagination, useTheme2 } from '@grafana/ui';
import { GetUsersQuery } from '__generated__/graphql';

import { BaseTable } from '../base';

import { GET_USERS } from './AccessControlQueries';
import { UserRow } from './UserRow';

interface UserTableType {
  userSearch: string;
  roleSearch: string;
  offset: number;
  onNavigate: (page: number) => void;
  pageSize: number;
}

export const UserTable = ({ userSearch, roleSearch, offset, onNavigate, pageSize }: UserTableType) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  const { data } = useSuspenseQuery<GetUsersQuery>(GET_USERS, {
    variables: {
      filters: {
        ...(userSearch && { name: userSearch }),
        ...(roleSearch && { role: roleSearch }),
      },
      first: pageSize,
      after: offset,
    },
  });

  return (
    <Stack direction="column">
      <BaseTable theme={theme}>
        <thead>
          <tr>
            <th className={styles.userColumn}>User</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {data!.users.response.map((user, index) => (
            <UserRow user={user} key={index} />
          ))}
        </tbody>
      </BaseTable>
      <Stack direction="row-reverse">
        <Pagination
          currentPage={Math.ceil((offset + 1) / pageSize)}
          numberOfPages={Math.ceil(data!.users.metadata.totalCount / pageSize)}
          onNavigate={onNavigate}
          hideWhenSinglePage
        />
      </Stack>
    </Stack>
  );
};

const getStyles = () => ({
  userColumn: css({
    width: '85%',
  }),
});
