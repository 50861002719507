import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Card, Divider, LinkButton, Stack, useStyles2 } from '@grafana/ui';
import { License, LicenseCategory } from '__generated__/graphql';
import { testIds } from 'shared/testIds';

import { capitalizeFirstLetter } from '../utils';

import { LicensePreview } from './LicensePreview';

interface LicensePreviewContainerProps {
  activeTarget?: string;
  activePkg?: string;
  activeLicense?: License;
}

const PreviewContainer = ({ children }: { children?: React.ReactNode }) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.licensePreview} data-testid={testIds.licenses.container}>
      <Stack direction="column">{children}</Stack>
    </div>
  );
};

const moreInfoLink = (category: LicenseCategory): string => {
  const link = 'https://opensource.google/documentation/reference/thirdparty/licenses';
  switch (category) {
    case LicenseCategory.Notice:
    case LicenseCategory.Restricted:
    case LicenseCategory.Permissive:
    case LicenseCategory.Unencumbered:
    case LicenseCategory.Reciprocal:
      return `${link}#${category.toLowerCase()}`;
    default:
      return link;
  }
};

const licenseDescription = (category: LicenseCategory): string => {
  switch (category) {
    case LicenseCategory.Restricted:
    case LicenseCategory.Forbidden:
      return 'This license requires mandatory source code distribution if third-party code under such a license is included in a product.';
    case LicenseCategory.Notice:
      return 'This license allows unrestricted use of third-party software but requires inclusion of the original copyright notice in external distributions.';
    case LicenseCategory.Permissive:
      return 'This license allows use of third-party software without requiring a copyright notice, typically for cases like adding header files to the include path without distributing binary libraries or source files.';
    case LicenseCategory.Unencumbered:
      return 'This license type allows free use of third-party software without any restrictions or requirements, such as copyright notices.';
    case LicenseCategory.Reciprocal:
      return 'This license requires source code distribution for the reciprocally-licensed library and its modifications, but not for other components of the software using the library.';
    default:
      return 'This license could not be classified. As this license may be in violation, it is recommended to review this unknown license.';
  }
};

export const LicensePreviewContainer = ({ activeTarget, activePkg, activeLicense }: LicensePreviewContainerProps) => {
  if (!activeTarget || !activeLicense || !activePkg) {
    return <PreviewContainer />;
  }

  const { target, package: pkg, filePath, name, link, category } = activeLicense;
  if (activeTarget !== target || (activePkg !== pkg && activePkg !== filePath)) {
    return <PreviewContainer />;
  }

  return (
    <PreviewContainer>
      <Card>
        <Card.Heading>{capitalizeFirstLetter(category)}</Card.Heading>
        <Card.Description>{licenseDescription(category)}</Card.Description>
        <Card.Tags>
          <LinkButton href={moreInfoLink(category)} target="_blank" rel="noopener noreferrer" icon="external-link-alt">
            More Info
          </LinkButton>
        </Card.Tags>
      </Card>
      <Divider direction="horizontal" />
      <LicensePreview licenseName={name} link={link} />
    </PreviewContainer>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  licensePreview: css({
    width: '52.5%',
    height: `calc(100vh - ${theme.spacing(44)})`,
    overflow: 'auto',
    scrollbarColor: `${theme.colors.secondary.transparent} ${theme.colors.background.primary}`,
    gridTemplateColumns: '1fr auto',
    '#logo': {
      filter: 'invert(100%) grayscale(100%)',
    },
    '.breadcrumb': {
      display: 'none',
    },
    '.gray-diagonal': {
      display: 'none',
    },
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }),
});
