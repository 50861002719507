import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Input, Text, useStyles2 } from '@grafana/ui';
import { BugBountySubmission, ModifyBugBountySubmissionMutation } from '__generated__/graphql';
import { memo, useEffect, useRef, useState } from 'react';

import { MODIFY_BUG_BOUNTY_SUBMISSION } from './ExploreBugBountySubmissionsMutations';

interface CveEntryBoxType {
  submission: BugBountySubmission;
}

const CveEntryBox = memo(({ submission }: CveEntryBoxType) => {
  const styles = useStyles2(getStyles);

  const [isEditing, setIsEditing] = useState(false);
  const [cveText, setCveText] = useState(submission.cve || '');
  const cellRef = useRef(null);

  const [modifyBugBountySubmission] = useMutation<ModifyBugBountySubmissionMutation>(MODIFY_BUG_BOUNTY_SUBMISSION, {
    optimisticResponse: {
      modifyBugBountySubmission: {
        __typename: 'BugBountySubmission',
        submissionCode: submission.submissionCode,
        squad: submission.squad,
        status: submission.status,
        cve: cveText,
      },
    },
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to update bug bounty submission cve`],
      });
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCveText(event.target.value);
  };

  const handleSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      modifyBugBountySubmission({
        variables: {
          input: {
            submissionCode: submission.submissionCode,
            cve: cveText,
          },
        },
      });
      setIsEditing(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (cellRef.current && !cellRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  return (
    <td ref={cellRef} onClick={() => setIsEditing(true)} className={styles.cell}>
      {isEditing && (
        <Input
          value={cveText}
          onChange={handleInputChange}
          onBlur={() => setIsEditing(false)}
          width={18}
          autoFocus
          onKeyDown={handleSubmit}
        />
      )}
      {!isEditing && submission.cve}
      {!isEditing && !submission.cve && (
        <Text italic color="secondary">
          No CVE Assigned
        </Text>
      )}
    </td>
  );
});

const getStyles = () => ({
  cell: css({
    cursor: 'pointer',
  }),
});

CveEntryBox.displayName = 'CveEntryBox';

export { CveEntryBox };
