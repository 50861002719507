import {
  CustomVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { Secrets } from 'scenes/customScenes/Secrets';
import { SECRET_CATEGORIES, SECRET_SEVERITIES, SOURCES_ROUTE } from 'shared/constants';

interface GetSecretsTabType {
  apiUrl: string;
  sourceID: string;
  versionID: string;
}

// TODO remove this fn
const createOptions = (optionsObj: Record<string, string>) =>
  Object.entries(optionsObj)
    .map((obj) => `${obj[1]} : ${obj[0].length ? obj[0] : ' '}`)
    .join(',');

export const getSecretsTab = ({ apiUrl, sourceID, versionID }: GetSecretsTabType) => {
  const severityVariable = new CustomVariable({
    name: 'severity',
    label: 'Severity',
    value: '',
    query: createOptions(SECRET_SEVERITIES),
  });

  const categoryVariable = new CustomVariable({
    name: 'category',
    label: 'Category',
    value: '',
    query: createOptions(SECRET_CATEGORIES),
  });

  return new SceneAppPage({
    title: 'Secrets',
    titleIcon: 'key-skeleton-alt',
    key: 'secretsTab',
    url: `${SOURCES_ROUTE}/${sourceID}/version/${versionID}/secrets`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'secrets',
        $variables: new SceneVariableSet({
          variables: [categoryVariable, severityVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'column',
              children: [
                new SceneFlexItem({
                  body: new Secrets({
                    versionId: versionID,
                    category: '${category}',
                    severity: '${severity}',
                    simplified: true,
                  }),
                }),
              ],
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
  });
};
