import { TypedDocumentNode, gql } from '@apollo/client';
import { GetBugBountySubmissionQuery, GetBugBountySubmissionQueryVariables } from '__generated__/graphql';

export const GET_BUG_BOUNTY_SUBMISSION: TypedDocumentNode<
  GetBugBountySubmissionQuery,
  GetBugBountySubmissionQueryVariables
> = gql`
  query getBugBountySubmission($submissionCode: String!) {
    bugBountySubmission(submissionCode: $submissionCode) {
      submissionCode
      title
      url
    }
  }
`;
