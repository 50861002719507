import { getAppEvents } from '@grafana/runtime';
import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { BigValueColorMode } from '@grafana/schema';
import { SECRET_TOTAL_THRESHOLDS } from 'scenes/panelConstants';
import { SECRETS_ROUTE } from 'shared/constants';
import { SecretCardTagClickedEvent } from 'shared/events';

export const getSecretTotalsStat = (versionID = '0') =>
  PanelBuilders.stat()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'organize',
            options: {
              indexByName: {
                totalCount: 0,
              },
            },
          },
          {
            id: 'extractFields',
            options: {
              source: 'metadata',
            },
          },
        ],
      })
    )
    .setTitle(`Exposed Secrets`)
    .setDisplayMode('transparent')
    .setOption('colorMode', BigValueColorMode.Background)
    .setLinks([
      {
        title: 'secrets',
        url: `${SECRETS_ROUTE}?var-versionId=${versionID}`,
        onClick: () => getAppEvents().publish(new SecretCardTagClickedEvent({ versionID })),
      },
    ])
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('totalCount').overrideThresholds(SECRET_TOTAL_THRESHOLDS);
    })
    .build();
