import { LinkButton, LoadingPlaceholder, Stack } from '@grafana/ui';
import DOMPurify from 'dompurify';

import { useFetchLicense } from './useFetchLicense';

interface LicensePreviewProps {
  licenseName: string;
  link: string;
}

export const LicensePreview = ({ licenseName, link }: LicensePreviewProps) => {
  const { licenseDoc, loading } = useFetchLicense(licenseName);

  if (loading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  if (!licenseDoc) {
    return null;
  }

  if (typeof licenseDoc !== 'string') {
    return licenseDoc.error;
  }

  return (
    <>
      <Stack direction="row">
        <span
          // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(licenseDoc) }}
        />
        <LinkButton
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          icon="external-link-alt"
          style={{ alignSelf: 'flex-end' }}
        >
          SPDX
        </LinkButton>
      </Stack>
    </>
  );
};
