import pluginJson from '../plugin.json';

export const PLUGIN_ID = pluginJson.id;
export const PLUGIN_PROXY = `api/plugin-proxy/${PLUGIN_ID}`;
export const GQL_URL = `${PLUGIN_PROXY}/api-proxy/graphql/query`;
export const INTIGRITI_URL = `${PLUGIN_PROXY}/intigriti/external/company/v2`;
export const GITHUB_PREFIX = 'https://github.com/';

// Page routes
export const PLUGIN_BASE_URL = `/a/${PLUGIN_ID}`;

export const ABOUT_ROUTE = PLUGIN_BASE_URL;
export const SOURCES_ROUTE = `${PLUGIN_BASE_URL}/sources`;
export const ISSUES_ROUTE = `${PLUGIN_BASE_URL}/issues`;
export const CVES_ROUTE = `${PLUGIN_BASE_URL}/cves`;
export const CVE_DETAILS_ROUTE = `${CVES_ROUTE}/details`;
export const CVE_EXCEPTIONS_ROUTE = `${CVES_ROUTE}/exceptions`;
export const CVE_EXCEPTIONS_CREATE_ROUTE = `${CVE_EXCEPTIONS_ROUTE}/create`;
export const CVE_EXCEPTION_DETAILS_ROUTE = `${CVE_EXCEPTIONS_ROUTE}/details`;
export const SLOS_ROUTE = `${PLUGIN_BASE_URL}/slos`;
export const SETTINGS_ROUTE = `${PLUGIN_BASE_URL}/settings`;
export const CREATE_SOURCE_ROUTE = `${SETTINGS_ROUTE}/configure-source`;
export const BUG_BOUNTY_ROUTE = `${PLUGIN_BASE_URL}/bug-bounty`;
export const BUG_BOUNTY_REPORT_ROUTE = `${BUG_BOUNTY_ROUTE}/report`;
export const PLAN_RELEASE_ROUTE = `${PLUGIN_BASE_URL}/plan-release`;
export const K8S_SCANNING_ROUTE = `${PLUGIN_BASE_URL}/k8s`;
export const SECRETS_ROUTE = `${PLUGIN_BASE_URL}/secrets`;

// Image Routes
export const IMAGE_BASE_ROUTE = `public/plugins/${PLUGIN_ID}/img`;
export const GITHUB_LOGO_SVG = `${IMAGE_BASE_ROUTE}/github.svg`;
export const DOCKER_LOGO_SVG = `${IMAGE_BASE_ROUTE}/docker-mark-blue.svg`;
export const GCR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/gcr.svg`;
export const GAR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/gar.svg`;
export const K8S_LOGO_SVG = `${IMAGE_BASE_ROUTE}/k8s.svg`;
export const ECR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/ecr.svg`;
export const REDHAT_LOGO_SVG = `${IMAGE_BASE_ROUTE}/redhat.svg`;
export const REDPANDA_LOGO_SVG = `${IMAGE_BASE_ROUTE}/redpanda.svg`;
export const UPBOUND_LOGO_SVG = `${IMAGE_BASE_ROUTE}/upbound.svg`;

// Tracking events
export const APP_VISIT_EVENT = 'grafana_plugin_vulnerability_observability_visit';
export const PLAN_RELEASE_CLICK = 'grafana_plugin_sources_plan_release';
export const CVES_CARD_CLICK = 'grafana_plugin_cves_card_click';
export const SOURCES_CARD_CLICK = 'grafana_plugin_sources_card_click';
export const VERSIONS_CARD_CLICK = 'grafana_plugin_sources_versions_card_click';
export const SECRETS_CARD_CLICK = 'grafana_plugin_secrets_card_click';

// Local storage keys
export const PREFERRED_GROUP_LS_KEY = 'grafana.preferredGroup';
export const CVE_LS_KEY = 'grafana.currentCve';

// Source Repositories
export const REPOSITORIES = {
  GITHUB: 'github',
  DOCKER_HUB: 'hub.docker.com',
  GCR_US: 'us.gcr.io',
  GAR_US: 'us-docker.pkg.dev',
  DOCKER_IO: 'docker.io',
  QUAY_IO: 'quay.io',
  K8S_REGISTRY: 'registry.k8s.io',
  UPBOUND_XPKG: 'xpkg.upbound.io',
  REDPANDA_DOCKER: 'docker.redpanda.com',
  AWS_ECR: 'public.ecr.aws',
  GCR_GLOBAL: 'gcr.io',
  GHCR_IO: 'ghcr.io',
};

// Trivy secret scan categories
export const SECRET_CATEGORIES = Object.freeze({
  '': 'All',
  AWS: 'AWS',
  GitHub: 'GitHub',
  GitLab: 'GitLab',
  AsymmetricPrivateKey: 'AsymmetricPrivateKey',
  Shopify: 'Shopify',
  Slack: 'Slack',
  Google: 'Google',
  Stripe: 'Stripe',
  PyPI: 'PyPI',
  Heroku: 'Heroku',
  Twilio: 'Twilio',
  Age: 'Age',
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Adobe: 'Adobe',
  Alibaba: 'Alibaba',
  Asana: 'Asana',
  Atlassian: 'Atlassian',
  Bitbucket: 'Bitbucket',
  Beamer: 'Beamer',
  Clojars: 'Clojars',
  ContentfulDelivery: 'ContentfulDelivery',
  Databricks: 'Databricks',
  Discord: 'Discord',
  Doppler: 'Doppler',
  Dropbox: 'Dropbox',
  Duffel: 'Duffel',
  Dynatrace: 'Dynatrace',
  Easypost: 'Easypost',
  Fastly: 'Fastly',
  Finicity: 'Finicity',
  Flutterwave: 'Flutterwave',
  Frameio: 'Frameio',
  GoCardless: 'GoCardless',
  Grafana: 'Grafana',
  HashiCorp: 'HashiCorp',
  HubSpot: 'HubSpot',
  Intercom: 'Intercom',
  Ionic: 'Ionic',
  Linear: 'Linear',
  Lob: 'Lob',
  Mailchimp: 'Mailchimp',
  Mailgun: 'Mailgun',
  Mapbox: 'Mapbox',
  MessageBird: 'MessageBird',
  NewRelic: 'NewRelic',
  Npm: 'Npm',
  Planetscale: 'Planetscale',
  Postman: 'Postman',
  Pulumi: 'Pulumi',
  RubyGems: 'RubyGems',
  SendGrid: 'SendGrid',
  Sendinblue: 'Sendinblue',
  Shippo: 'Shippo',
  LinkedIn: 'LinkedIn',
  Twitch: 'Twitch',
  Typeform: 'Typeform',
});

// Trivy secret scan severity metadata
export const SECRET_SEVERITIES = Object.freeze({
  '': 'All',
  CRITICAL: 'Critical',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  UNKNOWN: 'Unknown',
});
