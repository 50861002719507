import { Field, Label, Stack, Tooltip, Text, Icon, Input } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { ContainerScanningConfig, RepositoryScanningConfig } from '../types';

interface LastTagsToScanFieldType<T extends RepositoryScanningConfig | ContainerScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const LastTagsToScanField = <T extends RepositoryScanningConfig | ContainerScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: LastTagsToScanFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Last Tags to Scan{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={<Text element="p">How many tags to scan. Use 0 to indicate no tags should be scanned.</Text>}
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'lastTagsToScan' as Path<T>}
        control={control}
        rules={{
          required,
          min: {
            value: 0,
            message: 'Must be greater than or equal to 0',
          },
        }}
        render={({ field }) => (
          <Input
            {...field}
            type="number"
            value={field.value as number}
            width={10}
            disabled={disabled}
            invalid={!!formState.errors.lastTagsToScan}
          />
        )}
      />
    </Field>
  );
};
