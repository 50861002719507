import { TypedDocumentNode, gql } from '@apollo/client';
import { GetLicensesQuery, GetLicensesQueryVariables } from '__generated__/graphql';

export const GET_LICENSES: TypedDocumentNode<GetLicensesQuery, GetLicensesQueryVariables> = gql`
  query getLicenses($filters: LicenseFilters, $first: Int, $after: Int) {
    licenses(filters: $filters, first: $first, after: $after) {
      response {
        id
        target
        category
        package
        filePath
        name
        confidence
        link
      }
    }
  }
`;
