import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { getAppEvents, usePluginInteractionReporter } from '@grafana/runtime';
import { Card, Text, useStyles2, Stack, TagList } from '@grafana/ui';
import { Version, Source } from '__generated__/graphql';
import { VERSIONS_CARD_CLICK, SOURCES_ROUTE } from 'shared/constants';
import { K8sCardNamespaceClickedEvent } from 'shared/events';

import { SeverityCounts } from '../VersionSummary/SeverityCounts';
import { formatDate, getSourceImage } from '../utils';

interface K8sSummaryCardProps {
  clusterId: string;
  version: Version;
  source: Source;
}

export interface CveCounts {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export const K8sSummaryCard = ({ clusterId, version, source }: K8sSummaryCardProps) => {
  const styles = useStyles2(getStyles);
  const report = usePluginInteractionReporter();
  const versionRoute = `${SOURCES_ROUTE}/${source.id}/version/${version.id}`;
  const sourceImage = getSourceImage(source.origin);

  const namespaces = version.k8sNamespaces.reduce((acc, ns) => {
    if (ns.clusterId.toString() === clusterId) {
      acc.push(ns.name);
    }
    return acc;
  }, [] as string[]);

  const onTagClick = (tag: string) => {
    const payload = {
      id: version.k8sNamespaces.find((namespace) => namespace.name === tag)?.id!,
      name: tag,
    };
    getAppEvents().publish(new K8sCardNamespaceClickedEvent(payload));
  };

  return (
    <Card
      href={versionRoute}
      onClick={() =>
        report(VERSIONS_CARD_CLICK, {
          version_id: version.id,
        })
      }
    >
      <Card.Figure>
        <img src={sourceImage} alt="logo" height="40px" width="40px" className={styles.logo} />
      </Card.Figure>
      <Card.Heading>
        {source.name}:{version.version}
        {version.isMostRecentVersion && <Text color="secondary"> (latest)</Text>}
      </Card.Heading>
      <Card.Meta>{`Last Scan: ${formatDate(new Date(version.latestScan))}`}</Card.Meta>
      <div className={styles.descriptionArea}>
        <Stack direction="column">
          <Stack direction="row" alignItems="center">
            <Text color="primary">CVEs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            <SeverityCounts counts={version.totalCveCounts} />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Text color="primary">Out of SLO</Text>
            <SeverityCounts counts={version.totalCveCountsOutOfSlo} isSlo />
          </Stack>
        </Stack>
      </div>
      <Card.Tags>
        <TagList tags={namespaces} displayMax={10} onClick={onTagClick} />
      </Card.Tags>
    </Card>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  logo: css({
    objectFit: 'contain',
  }),
  descriptionArea: css({
    gridArea: 'Description',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }),
});
