import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { BarGaugeDisplayMode, BarGaugeValueMode, FieldColorModeId, TableCellDisplayMode } from '@grafana/schema';
import { ColorBoxCell } from 'scenes/components/ColorBoxCell';
import { ActionsCell } from 'scenes/components/IssuesTable';
import {
  CVSS_SCORE_THRESHOLDS,
  SEVERITY_MAPPINGS,
  SLO_REMAINING_THRESHOLDS,
  TOOL_MAPPINGS,
} from 'scenes/panelConstants';
import { CVE_DETAILS_ROUTE } from 'shared/constants';

export const getRelatedCvesTable = (pkg: string) =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'filterByValue',
            options: {
              filters: [
                {
                  fieldName: 'package',
                  config: {
                    id: 'equal',
                    options: {
                      value: pkg,
                    },
                  },
                },
              ],
              type: 'include',
              match: 'any',
            },
          },
          {
            id: 'organize',
            options: {
              excludeByName: {
                package: true,
                cveId: true,
              },
              indexByName: {
                cve: 0,
                severity: 1,
                cvssScore: 2,
                target: 3,
                installedVersion: 4,
                fixedVersion: 5,
                sloRemaining: 6,
                toolName: 7,
                id: 8,
              },
              renameByName: {
                cve: 'CVE',
                severity: 'Severity',
                cvssScore: 'CVSS',
                target: 'Target',
                installedVersion: 'Installed',
                fixedVersion: 'Fixed In',
                sloRemaining: 'SLO Remaining',
                toolName: 'Tool',
                id: 'Actions',
              },
            },
          },
          {
            id: 'sortBy',
            options: {
              sort: [
                {
                  field: 'SLO Remaining',
                },
              ],
            },
          },
        ],
      })
    )
    .setTitle(`CVEs related to ${pkg}`)
    .setThresholds(SLO_REMAINING_THRESHOLDS)
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('CVE')
        .overrideLinks([
          {
            title: 'See ${__data.fields.cve}',
            url: `${CVE_DETAILS_ROUTE}/\${__data.fields.cve}`,
          },
        ])
        .overrideCustomFieldConfig('filterable', true);

      overridesBuilder
        .matchFieldsWithName('Severity')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('width', 110)
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideMappings(SEVERITY_MAPPINGS);

      overridesBuilder
        .matchFieldsWithName('Installed')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: 'red',
        });

      overridesBuilder
        .matchFieldsWithName('Fixed In')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: 'green',
        });

      overridesBuilder
        .matchFieldsWithName('CVSS')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('width', 80)
        .overrideDecimals(1)
        .overrideNoValue('0.0')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ColorBoxCell,
        })
        .overrideThresholds(CVSS_SCORE_THRESHOLDS);

      overridesBuilder
        .matchFieldsWithName('toolName')
        .overrideCustomFieldConfig('width', 100)
        .overrideCustomFieldConfig('align', 'center')
        .overrideMappings(TOOL_MAPPINGS);

      overridesBuilder
        .matchFieldsWithName('SLO Remaining')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.Gauge,
          mode: BarGaugeDisplayMode.Basic,
          valueDisplayMode: BarGaugeValueMode.Text,
        })
        .overrideCustomFieldConfig('align', 'center')
        .overrideUnit('days');

      overridesBuilder
        .matchFieldsWithName('Actions')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ActionsCell,
        })
        .overrideCustomFieldConfig('width', 100);

      overridesBuilder.matchFieldsWithName('isDismissed').overrideCustomFieldConfig('hidden', true);
    })
    .build();
