import { SelectableValue } from '@grafana/data';
import { Field, RadioButtonGroup, Box, Label, Stack, Tooltip, Text, Icon } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';
import { REPOSITORIES, DOCKER_LOGO_SVG, GAR_LOGO_SVG, GCR_LOGO_SVG } from 'shared/constants';

import type { ContainerScanningConfig } from '../types';

export const SOURCE_REPOSITORY_OPTIONS: Array<SelectableValue<string>> = [
  {
    label: 'Dockerhub',
    value: REPOSITORIES.DOCKER_HUB,
    imgUrl: DOCKER_LOGO_SVG,
  },
  {
    label: 'GCR',
    value: REPOSITORIES.GCR_US,
    imgUrl: GCR_LOGO_SVG,
  },
  {
    label: 'GAR',
    value: REPOSITORIES.GAR_US,
    imgUrl: GAR_LOGO_SVG,
  },
];

interface ContainerRepositoryFieldType<T extends ContainerScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const ContainerRepositoryField = <T extends ContainerScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: ContainerRepositoryFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Repository{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={
                <Text element="p">
                  {disabled
                    ? 'Repository should not be modified after a source is created. Please contact #security for support.'
                    : 'Where the image is stored'}
                </Text>
              }
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'repository' as Path<T>}
        rules={{ required }}
        control={control}
        render={({ field }) => (
          <Box width={40}>
            <RadioButtonGroup
              {...field}
              size="md"
              fullWidth
              options={SOURCE_REPOSITORY_OPTIONS}
              invalid={!!formState.errors.repository}
              disabled={disabled}
            />
          </Box>
        )}
      />
    </Field>
  );
};
