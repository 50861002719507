import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexLayout,
  SceneFlexItem,
  VariableValueSelectors,
  SceneReactObject,
  NestedScene,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  QueryVariable,
} from '@grafana/scenes';
import { AppMenu } from 'scenes/components/AppMenu';
import { SwitchVariable } from 'scenes/controls/SwitchVariable';
import { baseOpts, getInfinityDatasource, urlOpts } from 'scenes/datasources/infinity';
import { ABOUT_ROUTE } from 'shared/constants';

import {
  getCveTotalsStat,
  getMeanTimeToResolveTimeseries,
  getVersionSloBarGauge,
  getCvesPatchedTimeseries,
  getSourcesWithSloTable,
} from './panels';
import { getSecretTotalsStat } from './panels/getSecretTotalsStat';
import {
  getSloTotalsQueryRunner,
  getSourcesWithSloQueryRunner,
  getTotalCveCountsQueryRunner,
  getWeeklyCvesPatchedQueryRunner,
  getWeeklyTimeToResolutionQueryRunner,
} from './queryRunners';
import { getTotalSecretsCountsQueryRunner } from './queryRunners/getTotalSecretCountsQueryRunner';

interface GetAboutPageType {
  apiUrl: string;
}

export const getAboutPage = ({ apiUrl }: GetAboutPageType) => {
  return new SceneAppPage({
    title: 'Vulnerability Observability',
    subTitle: 'Manage and explore your vulnerability posture using Grafana.',
    key: 'aboutPage',
    url: ABOUT_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'about',
        body: new SceneFlexLayout({
          direction: 'column',
          width: '100%',
          children: [
            new SceneFlexLayout({
              width: '100%',
              height: 'max-content',
              children: [
                new SceneReactObject({
                  component: AppMenu,
                }),
              ],
            }),
            new NestedScene({
              title: 'Overview',
              $timeRange: new SceneTimeRange({
                from: 'now-90d',
                to: 'now',
              }),
              $variables: new SceneVariableSet({
                variables: [
                  new QueryVariable({
                    name: 'group',
                    label: 'Group',
                    datasource: getInfinityDatasource(),
                    placeholder: 'Select Group',
                    includeAll: true,
                    defaultToAll: true,
                    allValue: '0',
                    query: {
                      refId: 'variable-group',
                      infinityQuery: {
                        ...baseOpts,
                        url: apiUrl,
                        root_selector: 'data.groups',
                        url_options: {
                          ...urlOpts,
                          body_graphql_query: `
                            query getGroups {
                              groups {
                                __value: id
                                __text: name
                              }
                            }
                          `,
                        },
                      },
                      queryType: 'infinity',
                    },
                  }),
                  new SwitchVariable({
                    name: 'latestOnly',
                    label: 'Latest Versions Only',
                    value: true,
                  }),
                ],
              }),
              body: new SceneFlexLayout({
                width: '100%',
                direction: 'column',
                children: [
                  new SceneFlexLayout({
                    width: '100%',
                    height: '130px',
                    children: [
                      new SceneFlexItem({
                        $data: getTotalCveCountsQueryRunner(apiUrl),
                        body: getCveTotalsStat(),
                      }),
                      new SceneFlexItem({
                        $data: getSloTotalsQueryRunner(apiUrl),
                        body: getVersionSloBarGauge(),
                      }),
                      new SceneFlexItem({
                        width: '15%',
                        $data: getTotalSecretsCountsQueryRunner(apiUrl),
                        body: getSecretTotalsStat(),
                      }),
                    ],
                  }),
                  new SceneFlexLayout({
                    width: '100%',
                    height: '300px',
                    children: [
                      new SceneFlexItem({
                        $data: getWeeklyTimeToResolutionQueryRunner(apiUrl),
                        body: getMeanTimeToResolveTimeseries(),
                      }),
                      new SceneFlexItem({
                        $data: getWeeklyCvesPatchedQueryRunner(apiUrl),
                        body: getCvesPatchedTimeseries(),
                      }),
                    ],
                  }),
                  new SceneFlexItem({
                    width: '100%',
                    height: '600px',
                    $data: getSourcesWithSloQueryRunner(apiUrl),
                    body: getSourcesWithSloTable(),
                  }),
                ],
              }),
              controls: [new VariableValueSelectors({}), new SceneTimePicker({})],
            }),
          ],
        }),
      });
    },
  });
};
