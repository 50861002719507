import { TypedDocumentNode, gql } from '@apollo/client';
import { GetSecretsQuery, GetSecretsQueryVariables } from '__generated__/graphql';

export const GET_SECRETS: TypedDocumentNode<GetSecretsQuery, GetSecretsQueryVariables> = gql`
  query getSecrets($filters: SecretFilters, $first: Int, $after: Int) {
    secrets(filters: $filters, first: $first, after: $after) {
      response {
        id
        target
        category
        title
        severity
        startPreview
        endPreview
        preview
        startLine
        endLine
        dockerCreatedBy
        dockerDiffId
        version {
          id
          imageId
          tag
          version
          source {
            id
            name
            origin
          }
        }
      }
    }
  }
`;
