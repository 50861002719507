import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Card, Icon, useStyles2, Tooltip } from '@grafana/ui';
import React from 'react';

interface LicenseListItemProps {
  itemName: string;
  onSelect: () => void;
  selected: boolean;
  figure?: React.JSX.Element;
  meta?: React.JSX.Element;
}

export const LicenseListItem = ({ itemName, onSelect, selected, figure, meta }: LicenseListItemProps) => {
  const styles = useStyles2(getStyles, selected);
  return (
    <Card className={styles.card} onClick={() => onSelect()} isSelected={selected}>
      {figure && <Card.Figure className={styles.figure}>{figure}</Card.Figure>}
      {meta && <Card.Meta>{meta}</Card.Meta>}
      <Card.Heading className={styles.itemName}>{itemName}</Card.Heading>
      <Card.Tags>
        <Tooltip content={itemName} interactive>
          <Icon name="angle-right" style={{ marginRight: '10px' }} color="grey" />
        </Tooltip>
      </Card.Tags>
    </Card>
  );
};

const getStyles = (theme: GrafanaTheme2, selected: boolean) => ({
  card: css({
    padding: theme.spacing(1),
    ...(!selected && { background: 'transparent' }),
    marginBottom: 0,
    borderRadius: theme.spacing(1),
    'input[type="radio"]': {
      display: 'none',
    },
    ...(selected && { outline: 'none' }),
  }),
  figure: css({
    marginRight: theme.spacing(3),
    zIndex: 1,
  }),
  itemName: css({
    width: '95%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
});
