import { Field, Label, TagsInput } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { GithubAlertConfig } from '../types';

interface GithubAssigneesFieldType<T extends GithubAlertConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const GithubAssigneesField = <T extends GithubAlertConfig>({
  control,
  formState,
  required,
  disabled,
}: GithubAssigneesFieldType<T>) => {
  return (
    <Field label={<Label>Assignees{required ? '*' : ''}</Label>}>
      <Controller
        name={'githubAssignees' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <TagsInput
            placeholder="New assignee (enter key to add)"
            tags={field.value as string[]}
            onChange={(tags) => field.onChange(tags)}
            width={40}
            invalid={!!formState.errors.githubAssignees}
            disabled={disabled}
          />
        )}
      />
    </Field>
  );
};
