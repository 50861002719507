import { useMutation, useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { useStyles2, Text, Button, ConfirmModal } from '@grafana/ui';
import {
  GetSourceVersionsQuery,
  DeleteVersionMutation,
  Version,
  ArchiveVersionMutation,
  UnarchiveVersionMutation,
  Role,
  Scan,
} from '__generated__/graphql';
import { useCallback, useState } from 'react';
import { useRole } from 'scenes/hooks';

import { looseSemverSort } from '../utils';

import { ARCHIVE_VERSION, DELETE_VERSION, UNARCHIVE_VERSION } from './ManageSourceMutations';
import { GET_SOURCE_VERSIONS } from './ManageSourceQueries';

interface ManageVersionsType {
  sourceID: string;
}

export const ManageVersions = ({ sourceID }: ManageVersionsType) => {
  const styles = useStyles2(getStyles);
  const { role } = useRole();

  const [selectedArchiveVersion, setSelectedArchiveVersion] = useState<Version | null>(null);
  const [selectedDeleteVersion, setSelectedDeleteVersion] = useState<Version | null>(null);

  const { data } = useSuspenseQuery<GetSourceVersionsQuery>(GET_SOURCE_VERSIONS, {
    variables: {
      filters: {
        sourceId: sourceID,
        showArchived: true,
      },
    },
  });

  const [archiveVersion] = useMutation<ArchiveVersionMutation>(ARCHIVE_VERSION, {
    refetchQueries: [GET_SOURCE_VERSIONS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to archive version`],
      });
    },
  });
  const [unarchiveVersion] = useMutation<UnarchiveVersionMutation>(UNARCHIVE_VERSION, {
    refetchQueries: [GET_SOURCE_VERSIONS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to restore version`],
      });
    },
  });
  const [deleteVersion] = useMutation<DeleteVersionMutation>(DELETE_VERSION, {
    refetchQueries: [GET_SOURCE_VERSIONS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to delete version`],
      });
    },
  });

  const handleArchiveVersion = (versionID: string) => {
    setSelectedArchiveVersion(null);
    archiveVersion({
      variables: {
        input: {
          versionId: versionID,
        },
      },
    });
  };

  const handleUnarchiveVersion = (versionID: string) => {
    unarchiveVersion({
      variables: {
        input: {
          versionId: versionID,
        },
      },
    });
  };

  const handleDeleteVersion = (versionID: string) => {
    setSelectedDeleteVersion(null);
    deleteVersion({
      variables: {
        input: {
          versionId: versionID,
        },
      },
    });
  };

  const getDaysSinceScan = useCallback((scans: Scan[]) => {
    const latestIngest = scans.reduce((max, scan) => {
      const currentIngestTime = new Date(scan.ingestTime).getTime();
      return currentIngestTime > max ? currentIngestTime : max;
    }, 0);

    const today = new Date().getTime();
    const timeDifference = today - latestIngest;
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (daysDifference === 0) {
      return 'less than a day';
    } else if (daysDifference === 1) {
      return '1 day';
    } else if (daysDifference > 365) {
      return 'over a year';
    } else {
      return `${daysDifference} days`;
    }
  }, []);

  return (
    <>
      {data?.versions.response.length ? (
        <>
          <table className={styles.table}>
            <thead>
              <td>Version</td>
              <td>Last Scan</td>
            </thead>
            <tbody>
              {looseSemverSort(data?.versions.response as Version[]).map((version, index) => (
                <tr key={index}>
                  <td>
                    <Text>{version.version}</Text>
                  </td>
                  <td>
                    <Text color="secondary">{getDaysSinceScan(version.scans)} ago</Text>
                  </td>
                  <td>
                    {version.isArchived ? (
                      <Button
                        icon="archive-alt"
                        variant="success"
                        size="sm"
                        onClick={() => handleUnarchiveVersion(version.id)}
                        className={styles.button}
                      >
                        Restore
                      </Button>
                    ) : (
                      <Button
                        icon="archive-alt"
                        variant="primary"
                        size="sm"
                        onClick={() => setSelectedArchiveVersion(version as Version)}
                        className={styles.button}
                      >
                        Archive
                      </Button>
                    )}
                    <Button
                      icon="trash-alt"
                      variant="destructive"
                      size="sm"
                      onClick={() => setSelectedDeleteVersion(version as Version)}
                      {...(role !== Role.Administrator && {
                        disabled: true,
                        tooltip: 'Only administrators may perform this action',
                      })}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ConfirmModal
            isOpen={Boolean(selectedDeleteVersion)}
            title={`Delete version ${selectedDeleteVersion?.version}?`}
            body={`
                    Are you sure you want to delete version ${selectedDeleteVersion?.version}?
                    This will remove the version, related issues and scans.
                  `}
            confirmText="Delete"
            onConfirm={() => handleDeleteVersion(selectedDeleteVersion?.id!)}
            onDismiss={() => setSelectedDeleteVersion(null)}
          />
          <ConfirmModal
            isOpen={Boolean(selectedArchiveVersion)}
            title={`Archive version ${selectedArchiveVersion?.version}?`}
            body={`
                    Archiving a version removes it from the version view. 
                    This will not delete the version or related issues 
                    and is a reversible action.
                  `}
            confirmText="Archive"
            confirmButtonVariant="primary"
            onConfirm={() => handleArchiveVersion(selectedArchiveVersion?.id!)}
            onDismiss={() => setSelectedArchiveVersion(null)}
          />
        </>
      ) : (
        <Text color="secondary" italic>
          No versions
        </Text>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    width: '100%',
    borderCollapse: 'collapse',
    tr: {
      borderTop: `1px solid ${theme.colors.border.strong}`,
    },
    'tr:last-child, tr:last-child': {
      borderBottom: `1px solid ${theme.colors.border.strong}`,
    },
    td: {
      padding: '8px 4px',
    },
    'td:nth-child(3)': {
      width: '175px',
    },
  }),
  button: css({
    margin: '0px 8px',
  }),
});
