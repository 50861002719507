import { useSuspenseQuery } from '@apollo/client';
import { SelectableValue } from '@grafana/data';
import { Field } from '@grafana/ui';
import { GetAllGroupsQuery } from '__generated__/graphql';
import { Control, Controller, Path } from 'react-hook-form';

import { GroupsInput } from '../GroupsInput';
import { GET_ALL_GROUPS } from '../ManageSource/ManageSourceQueries';

import { ContainerSourceFormType } from './ConfigureContainer';
import { RepositorySourceFormType } from './ConfigureRepository';

type ConfigureGroupsSupportedTypes = ContainerSourceFormType | RepositorySourceFormType;

interface ConfigureGroupsType<T extends ConfigureGroupsSupportedTypes> {
  control: Control<T>;
}

export const ConfigureGroups = <T extends ConfigureGroupsSupportedTypes>({ control }: ConfigureGroupsType<T>) => {
  const { data: allGroups } = useSuspenseQuery<GetAllGroupsQuery>(GET_ALL_GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  const options = allGroups.groups.map((gp) => ({ label: gp.name, value: gp.id }));

  return (
    <Field label="Groups">
      <Controller
        name={'groups' as Path<T>}
        control={control}
        render={({ field }) => (
          <GroupsInput
            groups={field.value}
            onAddGroup={(newGroup) => field.onChange([...field.value, { name: newGroup }])}
            onRemoveGroup={(groupToRemove) =>
              field.onChange(field.value.filter((group: SelectableValue<string>) => group.name !== groupToRemove))
            }
            placeholder="New group (enter key to add)"
            options={options}
          />
        )}
      />
    </Field>
  );
};
