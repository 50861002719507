import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

const filter = `isDismissed == false`;

export const getCvesWithSloQueryRunner = (apiUrl: string, versionID: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'A',
        url: apiUrl,
        root_selector: 'data.issues.response',
        columns: [
          {
            text: 'id',
            selector: 'id',
            type: 'string',
          },
          {
            text: 'cveId',
            selector: 'cve.id',
            type: 'string',
          },
          {
            text: 'cve',
            selector: 'cve.cve',
            type: 'string',
          },
          {
            text: 'cvssScore',
            selector: 'cve.cvssScore',
            type: 'number',
          },
          {
            text: 'severity',
            selector: 'cve.severity',
            type: 'string',
          },
          {
            text: 'sloRemaining',
            selector: 'sloRemaining',
            type: 'number',
          },
          {
            text: 'isDismissed',
            selector: 'isDismissed',
            type: 'boolean',
          },
          {
            text: 'package',
            selector: 'package',
            type: 'string',
          },
          {
            text: 'target',
            selector: 'target',
            type: 'string',
          },
          {
            text: 'installedVersion',
            selector: 'installedVersion',
            type: 'string',
          },
          {
            text: 'fixedVersion',
            selector: 'fixedVersion',
            type: 'string',
          },
          {
            text: 'toolName',
            selector: 'tool.name',
            type: 'string',
          },
        ],
        filterExpression: filter,
        url_options: {
          body_graphql_query: `
             query getCvesWithSlo($filters: IssueFilters, $first: Int, $after: Int) {
                issues(filters: $filters, first: $first, after: $after) {
                  response {
                    id
                    isDismissed
                    package
                    target
                    installedVersion
                    fixedVersion
                    cve {
                      id
                      cve
                      cvssScore
                      severity
                    }
                    sloRemaining
                    tool {
                      name
                    }
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              versionId: versionID,
              cve: '${cve}',
              package: '${pkg}',
            },
          }),
        },
      },
    ])
  );
