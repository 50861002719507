import { useSuspenseQuery } from '@apollo/client';
import { Alert, ErrorBoundary, LinkButton } from '@grafana/ui';
import { GetBugBountySubmissionQuery } from '__generated__/graphql';

import { GET_BUG_BOUNTY_SUBMISSION } from './BugBountyReportQueries';

interface BugBountyReportHeaderType {
  submissionCode: string;
}

const Controls = ({ submissionCode }: BugBountyReportHeaderType) => {
  const { data } = useSuspenseQuery<GetBugBountySubmissionQuery>(GET_BUG_BOUNTY_SUBMISSION, {
    variables: {
      submissionCode,
    },
  });

  const { bugBountySubmission } = data;

  if (!bugBountySubmission) {
    return null;
  }

  const { url } = bugBountySubmission;

  return (
    <LinkButton href={url} target="_blank" rel="noopener noreferrer" icon="external-link-alt">
      View Full Report
    </LinkButton>
  );
};

export const BugBountyReportControls = ({ submissionCode }: BugBountyReportHeaderType) => (
  <ErrorBoundary>
    {({ error }) => {
      if (error) {
        return (
          <Alert
            title="Error: failed to load controls"
            onRemove={() => window.location.reload()}
            buttonContent="Reload"
          >
            {error.message}
          </Alert>
        );
      }
      return <Controls submissionCode={submissionCode} />;
    }}
  </ErrorBoundary>
);
