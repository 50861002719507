import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Icon, IconName, IconSize, Tooltip, useStyles2 } from '@grafana/ui';
import { LicenseCategory } from '__generated__/graphql';
import { useEffect, useMemo, useState } from 'react';

import { capitalizeFirstLetter, hexToRgba } from '../utils';

interface CategoryeBoxType {
  category: LicenseCategory;
}

export const CategoryBox = ({ category }: CategoryeBoxType) => {
  const styles = useStyles2(getStyles);
  const [name, setName] = useState<IconName>('question-circle');
  const [size, setSize] = useState<IconSize>();

  let background = useMemo(() => {
    let background = styles.none;
    switch (category) {
      case LicenseCategory.Restricted:
      case LicenseCategory.Forbidden:
        background = styles.notAllowed;
        break;
      case LicenseCategory.Reciprocal:
        background = styles.reciprocal;
        break;
      case LicenseCategory.Notice:
        background = styles.notice;
        break;
      case LicenseCategory.Permissive:
        background = styles.permissive;
        break;
      case LicenseCategory.Unencumbered:
        background = styles.unencumbered;
        break;
      default:
        background = styles.none;
    }
    return background;
  }, [category, styles]);

  useEffect(() => {
    switch (category) {
      case LicenseCategory.Restricted:
      case LicenseCategory.Forbidden:
        setName('times');
        setSize('xl');
        break;
      case LicenseCategory.Reciprocal:
        setName('exchange-alt');
        setSize('lg');
        break;
      case LicenseCategory.Notice:
        setName('exclamation');
        setSize('xl');
        break;
      case LicenseCategory.Permissive:
      case LicenseCategory.Unencumbered:
        setName('check');
        setSize('lg');
        break;
      default:
        setName('question-circle');
        setSize('xl');
    }
  }, [category]);

  return (
    <Tooltip content={capitalizeFirstLetter(category)}>
      <div className={cx(styles.box, background)}>
        <Icon name={name} size={size} />
      </div>
    </Tooltip>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.radius.default,
    minWidth: '40px',
    minHeight: '40px',
    color: theme.colors.text.maxContrast,
  }),
  notAllowed: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-red')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-red'), 0.08),
    color: theme.visualization.getColorByName('dark-red'),
  }),
  reciprocal: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-orange')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-orange'), 0.08),
    color: theme.visualization.getColorByName('dark-orange'),
  }),
  notice: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-yellow')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-yellow'), 0.08),
    color: theme.visualization.getColorByName('dark-yellow'),
  }),
  permissive: css({
    border: `1px solid ${theme.visualization.getColorByName('green')}`,
    background: hexToRgba(theme.visualization.getColorByName('green'), 0.08),
    color: theme.visualization.getColorByName('green'),
  }),
  unencumbered: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-green')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-green'), 0.08),
    color: theme.visualization.getColorByName('dark-green'),
  }),
  none: css({
    border: `1px solid ${theme.colors.secondary.border}`,
    background: hexToRgba(theme.colors.secondary.border, 0.08),
    color: theme.visualization.getColorByName('grey'),
  }),
});
