import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Stack, useStyles2 } from '@grafana/ui';
import React from 'react';

import { LicenseListItem } from './LicenseListItem';

type SetItem<T> = React.Dispatch<React.SetStateAction<T | undefined>>;

interface LicenseListProps<T> {
  items: T[];
  activeItem: T | undefined;
  setItem: SetItem<T>;
  getItemName: (item: T) => string;
  equals: (item: T, activeItem: T | undefined) => boolean;
  figure?: (item: T) => React.JSX.Element;
  meta?: (item: T) => React.JSX.Element;
}

export const LicenseList = <T,>({
  items,
  activeItem,
  getItemName,
  setItem,
  equals,
  figure,
  meta,
}: LicenseListProps<T>) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.scroll}>
      <Stack direction="column">
        {items.map((item, idx) => (
          <LicenseListItem
            itemName={getItemName(item)}
            key={idx}
            onSelect={() => (equals(item, activeItem) ? setItem(undefined) : setItem(item))}
            selected={equals(item, activeItem)}
            figure={figure && figure(item)}
            meta={meta && meta(item)}
          />
        ))}
      </Stack>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  scroll: css({
    width: '15%',
    height: `calc(100vh - ${theme.spacing(44)})`,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarColor: `${theme.colors.secondary.transparent} ${theme.colors.background.primary}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
});
