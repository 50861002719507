import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getTotalSecretsCountsQueryRunner = (apiUrl: string, versionId?: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'K',
        url: apiUrl,
        root_selector: 'data.summary.totalSecretCount',
        url_options: {
          body_graphql_query: `
            query getSummary($filters: SummaryFilters) {
              summary(filters: $filters) {
                totalSecretCount
              }
            }
          `,
          body_graphql_variables: JSON.stringify({
            filters: {
              groupId: '$group',
              latestOnly: '$latestOnly',
              fromConfigScanOnly: '$fromConfigScanOnly',
            },
          }),
        },
      },
    ])
  );
