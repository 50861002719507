import { FetchResponse } from '@grafana/runtime';
import { PLUGIN_PROXY } from 'shared/constants';
import { ErrorResponse } from 'types';

import { fetchApi } from './baseApi';

export const getLicense = async (name: string): Promise<FetchResponse<string | ErrorResponse>> => {
  const res = await fetchApi<string>(`${PLUGIN_PROXY}/licenses/licenses/${name}.html`, {
    method: 'GET',
  });
  return res;
};
