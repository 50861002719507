import { TypedDocumentNode, gql } from '@apollo/client';
import {
  CreateContainerSourceMutation,
  CreateContainerSourceMutationVariables,
  CreateRepositorySourceMutation,
} from '__generated__/graphql';

export const CREATE_CONTAINER_SOURCE: TypedDocumentNode<
  CreateContainerSourceMutation,
  CreateContainerSourceMutationVariables
> = gql`
  mutation createContainerSource($input: CreateContainerSourceConfigInput!) {
    createContainerSourceConfig(input: $input) {
      id
    }
  }
`;

export const CREATE_REPOSITORY_SOURCE: TypedDocumentNode<
  CreateRepositorySourceMutation,
  CreateContainerSourceMutationVariables
> = gql`
  mutation createRepositorySource($input: CreateRepositorySourceConfigInput!) {
    createRepositorySourceConfig(input: $input) {
      id
    }
  }
`;
