import { Field, Label, Stack, Tooltip, Text, Icon, Switch } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { RepositoryScanningConfig } from '../types';

interface ScanMainFieldType<T extends RepositoryScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const ScanMainField = <T extends RepositoryScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: ScanMainFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Scan Main{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={
                <Text element="p">
                  Scan the <Text variant="code">main</Text> branch of the repository
                </Text>
              }
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={`scanMain` as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Switch {...field} value={field.value as boolean} disabled={disabled} invalid={!!formState.errors.scanMain} />
        )}
      />
    </Field>
  );
};
