import { Field, Text, Tooltip, Stack, Label, Icon, Input } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { GithubAlertConfig } from '../types';

interface GithubRepositoryNameFieldType<T extends GithubAlertConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const GithubRepositoryNameField = <T extends GithubAlertConfig>({
  control,
  formState,
  required,
  disabled,
}: GithubRepositoryNameFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Repository Name{required ? '*' : ''}</span>
            <Tooltip interactive content={<Text element="p">grafana/my_repository_name</Text>}>
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'githubRepositoryName' as Path<T>}
        rules={{ required }}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value as string}
            invalid={!!formState.errors.githubRepositoryName}
            width={40}
            disabled={disabled}
          />
        )}
      />
    </Field>
  );
};
