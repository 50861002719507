import styled from '@emotion/styled';
import { GrafanaTheme2 } from '@grafana/data';

interface TableStylesProps {
  theme: GrafanaTheme2;
}

export const BaseTable = styled('table')<TableStylesProps>(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
  'thead tr': {
    backgroundColor: theme.colors.background.primary,
  },
  'tbody tr:nth-of-type(odd)': {
    backgroundColor: theme.colors.background.secondary,
  },
  'tbody tr:nth-of-type(even)': {
    backgroundColor: theme.colors.background.primary,
  },
  'th, td': {
    padding: theme.spacing(2),
  },
}));
