import { TypedDocumentNode, gql } from '@apollo/client';
import { GetVersionsForNamespacesQuery, GetVersionsForNamespacesQueryVariables } from '__generated__/graphql';

export const GET_VERSIONS_FOR_NAMESPACES: TypedDocumentNode<
  GetVersionsForNamespacesQuery,
  GetVersionsForNamespacesQueryVariables
> = gql`
  query getVersionsForNamespaces($filters: K8sNamespaceFilters, $first: Int, $after: Int) {
    k8sNamespaces(filters: $filters, first: $first, after: $after) {
      response {
        id
        name
        versions {
          id
          version
          isMostRecentVersion
          latestScan
          source {
            id
            origin
            name
          }
          totalCveCounts {
            critical
            high
            medium
            low
          }
          totalCveCountsOutOfSlo {
            critical
            high
            medium
            low
          }
          k8sNamespaces {
            id
            name
            clusterId
          }
        }
      }
    }
  }
`;
