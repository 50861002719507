import { useSuspenseQuery } from '@apollo/client';
import { Alert, ErrorBoundary, Stack, Text } from '@grafana/ui';
import { GetBugBountySubmissionQuery } from '__generated__/graphql';

import { GET_BUG_BOUNTY_SUBMISSION } from './BugBountyReportQueries';
interface BugBountyReportHeaderType {
  submissionCode: string;
}

const Header = ({ submissionCode }: BugBountyReportHeaderType) => {
  const { data } = useSuspenseQuery<GetBugBountySubmissionQuery>(GET_BUG_BOUNTY_SUBMISSION, {
    variables: {
      submissionCode,
    },
  });

  const { bugBountySubmission } = data;

  if (!bugBountySubmission) {
    return null;
  }

  const { title } = bugBountySubmission;

  return (
    <Stack justifyContent="flex-start">
      <Text element="h1">{title}</Text>
    </Stack>
  );
};

export const BugBountyReportHeader = ({ submissionCode }: BugBountyReportHeaderType) => (
  <ErrorBoundary>
    {({ error }) => {
      if (error) {
        return (
          <Alert title="Error: failed to load header" onRemove={() => window.location.reload()} buttonContent="Reload">
            {error.message}
          </Alert>
        );
      }
      return <Header submissionCode={submissionCode} />;
    }}
  </ErrorBoundary>
);
