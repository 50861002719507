import { useMutation } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { CollapsableSection, Button, LoadingPlaceholder, ConfirmModal } from '@grafana/ui';
import { CreateRepositorySourceConfigInput, CreateRepositorySourceMutation } from '__generated__/graphql';
import { Suspense, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ConfigureAlerting } from './ConfigureAlerting';
import { ConfigureGroups } from './ConfigureGroups';
import { ConfigureRepositoryScans } from './ConfigureRepositoryScans';
import { CREATE_REPOSITORY_SOURCE } from './ConfigureSourceMutations';
import {
  GithubAlertConfig,
  GroupConfig,
  RepositoryScanningConfig,
  SharedScanningConfig,
  SlackAlertConfig,
} from './types';

export interface RepositorySourceFormType
  extends RepositoryScanningConfig,
    SharedScanningConfig,
    GroupConfig,
    SlackAlertConfig,
    GithubAlertConfig {}

export const ConfigureRepository = () => {
  const [isScanOpen, setIsScanOpen] = useState<boolean>(true);
  const [isGroupOpen, setIsGroupOpen] = useState<boolean>(true);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(true);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<RepositorySourceFormType | null>(null);

  const { control, handleSubmit, watch, formState, reset } = useForm<RepositorySourceFormType>({
    defaultValues: {
      repositoryName: '',
      autoArchive: false,
      containerImages: [],
      lastTagsToScan: 0,
      excludedPaths: [],
      groups: [],
      slackAlertsEnabled: false,
      slackChannelName: '',
      slackSeverities: [],
      githubIssuesEnabled: false,
      githubCreateSubIssues: false,
      githubRepositoryName: '',
      githubLabels: [],
      githubAssignees: [],
      scanMain: true,
    },
  });

  const [createRepositorySource] = useMutation<CreateRepositorySourceMutation>(CREATE_REPOSITORY_SOURCE, {
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to create source`],
      });
    },
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: created source`],
      });
      setFormData(null);
      reset();
    },
  });

  const slackAlertsEnabled = watch('slackAlertsEnabled');
  const githubIssuesEnabled = watch('githubIssuesEnabled');

  const onSubmit: SubmitHandler<RepositorySourceFormType> = (data) => {
    setFormData(data);
    setIsConfirmOpen(true);
  };

  const handleConfirmSubmit = () => {
    if (formData) {
      const payload: CreateRepositorySourceConfigInput = {
        repositoryName: `grafana/${formData.repositoryName}`,
        autoArchive: formData.autoArchive,
        tagPrefix: formData.tagPrefix,
        containerImages: formData.containerImages,
        lastTagsToScan: formData.lastTagsToScan,
        excludedPaths: formData.excludedPaths,
        scanMain: formData.scanMain,
        alertingConfig: {
          githubAssignees: formData.githubAssignees,
          githubCreateSubIssues: formData.githubCreateSubIssues,
          githubIssuesEnabled: formData.githubIssuesEnabled,
          githubLabels: formData.githubLabels,
          githubRepositoryName: formData.githubRepositoryName,
          slackAlertsEnabled: formData.slackAlertsEnabled,
          slackChannelName: formData.slackChannelName,
          slackSeverities: formData.slackSeverities.map((val) => val.value!),
        },
        groups: formData.groups.map((group) => group.name),
      };

      createRepositorySource({
        variables: {
          input: payload,
        },
      });

      setIsConfirmOpen(false);
    }
  };

  const handleCancelSubmit = () => {
    setIsConfirmOpen(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CollapsableSection label="2. Configure Scans" isOpen={isScanOpen} onToggle={setIsScanOpen}>
          <ConfigureRepositoryScans control={control} formState={formState} />
        </CollapsableSection>
        <CollapsableSection label="3. Configure Groups" isOpen={isGroupOpen} onToggle={setIsGroupOpen}>
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <ConfigureGroups control={control} />
          </Suspense>
        </CollapsableSection>
        <CollapsableSection label="3. Configure Alerts" isOpen={isAlertOpen} onToggle={setIsAlertOpen}>
          <ConfigureAlerting
            control={control}
            formState={formState}
            slackAlertsEnabled={slackAlertsEnabled}
            githubIssuesEnabled={githubIssuesEnabled}
          />
        </CollapsableSection>
        <Button type="submit" size="md">
          Create Repository Source
        </Button>
      </form>
      <ConfirmModal
        isOpen={isConfirmOpen}
        title={`Confirm Source Creation`}
        body={`Are you sure you want to create source ${formData?.repositoryName}?`}
        confirmText="Create"
        confirmButtonVariant="success"
        onConfirm={handleConfirmSubmit}
        onDismiss={handleCancelSubmit}
      />
    </>
  );
};
