import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { EmptySearchResult, useStyles2 } from '@grafana/ui';
import type { GetSecretsQuery, Secret } from '__generated__/graphql';

import { SecretCard } from './SecretCard';
import { GET_SECRETS } from './SecretCardQueries';

interface SecretCardGridType {
  versionId: string;
  category: string;
  severity: string;
  target: string;
  first: string;
  after: string;
  type: string;
  simplified: boolean;
}

export const SecretCardGrid = ({
  target,
  versionId,
  category,
  severity,
  first,
  after,
  type,
  simplified,
}: SecretCardGridType) => {
  const styles = useStyles2(getStyles);
  const { data } = useSuspenseQuery<GetSecretsQuery>(GET_SECRETS, {
    variables: {
      filters: {
        ...(versionId.length && { versionId }),
        ...(category.length && { category }),
        ...(severity.length && { severity }),
        ...(target.length && { target }),
        type,
      },
      ...(first.length && { first }),
      ...(after.length && { after }),
    },
    fetchPolicy: 'cache-and-network',
  });

  const secrets = data.secrets.response as Secret[];
  const isPaginated = first.length && after.length;

  return (
    <div className={styles.gridContainer}>
      {secrets.length ? (
        secrets.map((secret, index) => (
          <SecretCard secret={secret} key={index} simplified={simplified} disable={!isPaginated} />
        ))
      ) : (
        <EmptySearchResult>No secrets were found.</EmptySearchResult>
      )}
    </div>
  );
};

const getStyles = () => ({
  gridContainer: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(680px, 1fr))',
    gap: '12px',
  }),
});
