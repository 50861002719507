// Copied from https://github.com/grafana/scenes/blob/main/packages/scenes-app/src/components/SceneRadioToggle.tsx
import { SelectableValue } from '@grafana/data';
import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { RadioButtonGroup } from '@grafana/ui';

export interface SceneRadioToggleState extends SceneObjectState {
  options: Array<SelectableValue<string>>;
  value: string;
  onChange: (value: string) => void;
}

export class RadioToggle extends SceneObjectBase<SceneRadioToggleState> {
  onChange = (value: string) => {
    this.setState({ value });
    this.state.onChange(value);
  };

  static Component = ({ model }: SceneComponentProps<RadioToggle>) => {
    const { options, value } = model.useState();

    return <RadioButtonGroup options={options} value={value} onChange={model.onChange} />;
  };
}
