import { ErrorBoundary, Alert, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { BugBountyReportTable } from './BugBountyReportTable';

interface BugBountyReportContainerType {
  submissionCode: string;
}

export const BugBountyReportContainer = ({ submissionCode }: BugBountyReportContainerType) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load bug bounty report details"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <BugBountyReportTable submissionCode={submissionCode} />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
