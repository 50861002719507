import { Input, Field, Text, Tooltip, Stack, Label, Icon } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { ContainerScanningConfig } from '../types';

interface ContainerImageNameFieldType<T extends ContainerScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const ContainerImageNameField = <T extends ContainerScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: ContainerImageNameFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Image Name{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={
                <div>
                  <Text element="p">
                    {disabled ? (
                      'Image name should not be modified after a source is created. Please contact #security for support.'
                    ) : (
                      <Stack direction="column" width="400px">
                        <Text element="p">
                          Dockerhub: <Text variant="code">&lt;dockerhub_org&gt;/&lt;image_name&gt;</Text>
                        </Text>
                        <Text element="p">
                          GCR: <Text variant="code">&lt;gcp_project_id&gt;/&lt;repo_name&gt;/&lt;image_name&gt;</Text>
                        </Text>
                        <Text element="p">
                          GAR: <Text variant="code">&lt;registry_name&gt;/&lt;repo_name&gt;/&lt;image_name&gt;</Text>
                        </Text>
                      </Stack>
                    )}
                  </Text>
                </div>
              }
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'imageName' as Path<T>}
        rules={{ required }}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value as string}
            width={40}
            invalid={!!formState.errors.imageName}
            disabled={disabled}
          />
        )}
      />
    </Field>
  );
};
