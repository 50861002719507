import { useMutation } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { ConfirmModal } from '@grafana/ui';
import { DeactivateCveExceptionMutation } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CVE_EXCEPTIONS_ROUTE } from 'shared/constants';

import { DEACTIVATE_CVE_EXCEPTION } from './ExceptionDetailsHeaderMutations';

interface DeactivateExceptionModalType {
  isOpen: boolean;
  onDismiss: () => void;
  exceptionID: string;
  cve: string;
}

export const DeactivateExceptionModal = ({ isOpen, onDismiss, exceptionID, cve }: DeactivateExceptionModalType) => {
  const navigate = useNavigate();

  const [deactivateException] = useMutation<DeactivateCveExceptionMutation>(DEACTIVATE_CVE_EXCEPTION, {
    ignoreResults: true,
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: deactivated exception on ${cve}`],
      });
      navigate(CVE_EXCEPTIONS_ROUTE);
    },
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to deactivate exception on ${cve}`],
      });
    },
  });

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={`Deactivate exception on ${cve}?`}
      body="This will mark related issues in all of the targeted versions as active."
      confirmText="Deactivate"
      confirmVariant="destructive"
      onConfirm={() => {
        deactivateException({ variables: { input: { id: exceptionID } } });
      }}
      onDismiss={onDismiss}
    />
  );
};
