import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getNamespaceCveCountsQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'B',
        url: apiUrl,
        root_selector: 'data.k8sNamespace.totalCveCounts',
        url_options: {
          body_graphql_query: `
              query getNamespaceCveCounts($id: ID!) {
                k8sNamespace(id: $id) {
                  id
                  totalCveCounts {
                    critical
                    high
                    medium
                    low
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            id: '${namespace}',
          }),
        },
      },
    ])
  );
