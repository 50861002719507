import { Input, Field, Text, Tooltip, Stack, Label, Icon } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { SlackAlertConfig } from '../types';

interface SlackChannelFieldType<T extends SlackAlertConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const SlackChannelField = <T extends SlackAlertConfig>({
  control,
  formState,
  required,
  disabled,
}: SlackChannelFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Slack Channel*</span>
            <Tooltip content={<Text element="p">Where to send alerts</Text>}>
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'slackChannelName' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Input {...field} prefix="#" width={40} invalid={!!formState.errors.slackChannelName} disabled={disabled} />
        )}
      />
    </Field>
  );
};
