import { ValueMapping } from '@grafana/data';
import { MappingType, ThresholdsMode } from '@grafana/schema';

export const RISK_LEVEL_MAPPINGS: ValueMapping[] = [
  {
    type: MappingType.ValueToText,
    options: {
      MAXIMUM: {
        text: 'Maximum',
        color: 'dark-purple',
        index: 0,
      },
      SEVERE: {
        text: 'Severe',
        color: 'dark-red',
        index: 1,
      },
      SIGNIFICANT: {
        text: 'Significant',
        color: 'dark-orange',
        index: 2,
      },
      MODERATE: {
        text: 'Moderate',
        color: 'dark-yellow',
        index: 3,
      },
      MINOR: {
        text: 'Minor',
        color: 'grey',
        index: 4,
      },
      NONE: {
        text: 'None',
        color: 'dark-green',
        index: 5,
      },
    },
  },
];

export const BUG_BOUNTY_SEVERITY_MAPPINGS: ValueMapping[] = [
  {
    type: MappingType.ValueToText,
    options: {
      Maximum: {
        color: 'dark-purple',
        index: 0,
      },
      Critical: {
        color: 'dark-red',
        index: 1,
      },
      High: {
        color: 'dark-orange',
        index: 2,
      },
      Medium: {
        color: 'dark-yellow',
        index: 3,
      },
      Low: {
        color: 'grey',
        index: 4,
      },
      Undecided: {
        color: 'dark-blue',
        index: 5,
      },
      None: {
        color: 'dark-green',
        index: 6,
      },
    },
  },
];

export const SEVERITY_MAPPINGS: ValueMapping[] = [
  {
    type: MappingType.ValueToText,
    options: {
      CRITICAL: {
        text: 'Critical',
        color: 'dark-red',
        index: 0,
      },
      HIGH: {
        text: 'High',
        color: 'dark-orange',
        index: 1,
      },
      MEDIUM: {
        text: 'Medium',
        color: 'dark-yellow',
        index: 2,
      },
      LOW: {
        text: 'Low',
        color: 'grey',
        index: 3,
      },
      UNKNOWN: {
        text: 'Unknown',
        color: 'dark-blue',
        index: 5,
      },
    },
  },
];

export const CVSS_SCORE_THRESHOLDS = {
  mode: ThresholdsMode.Absolute,
  steps: [
    { color: 'grey', value: -Infinity },
    { color: 'dark-yellow', value: 3.9 },
    { color: 'dark-orange', value: 6.9 },
    { color: 'dark-red', value: 9 },
  ],
};

export const SLO_REMAINING_THRESHOLDS = {
  mode: ThresholdsMode.Absolute,
  steps: [
    { color: 'dark-red', value: -Infinity },
    { color: 'dark-orange', value: 7 },
    { color: 'dark-yellow', value: 14 },
    { color: 'dark-green', value: 30 },
  ],
};

export const BUG_BOUNTY_PAYOUT_THRESHOLDS = {
  mode: ThresholdsMode.Absolute,
  steps: [
    { color: 'dark-green', value: -Infinity },
    { color: 'dark-yellow', value: 0.01 },
    { color: 'dark-orange', value: 500 },
    { color: 'dark-red', value: 3000 },
    { color: 'dark-purple', value: 10000 },
  ],
};

export const STATUS_MAPPINGS: ValueMapping[] = [
  {
    type: MappingType.ValueToText,
    options: {
      true: {
        text: 'Dismissed',
        color: 'dark-green',
        index: 0,
      },
      false: {
        text: 'Active',
        color: 'dark-red',
        index: 1,
      },
    },
  },
];

export const TOOL_MAPPINGS: ValueMapping[] = [
  {
    type: MappingType.ValueToText,
    options: {
      trivy: {
        text: 'Trivy',
        index: 0,
      },
      snyk: {
        text: 'Snyk',
        index: 1,
      },
    },
  },
];

export const SLO_STATUS_MAPPINGS: ValueMapping[] = [
  {
    type: MappingType.RangeToText,
    options: {
      from: -Infinity,
      to: 0,
      result: {
        text: 'Out of SLO',
        color: 'dark-red',
        index: 0,
      },
    },
  },
  {
    type: MappingType.RangeToText,
    options: {
      from: 1,
      to: 7,
      result: {
        text: 'Approaching SLO Breach',
        color: 'dark-orange',
        index: 1,
      },
    },
  },
  {
    type: MappingType.RangeToText,
    options: {
      from: 8,
      to: Infinity,
      result: {
        text: 'Inside SLO',
        color: 'dark-green',
        index: 2,
      },
    },
  },
];

export const SECRET_TOTAL_THRESHOLDS = {
  mode: ThresholdsMode.Absolute,
  steps: [
    { color: 'grey', value: -Infinity },
    { color: 'dark-green', value: 0 },
    { color: 'dark-red', value: 1 },
  ],
};

export const SECRET_SLO_TABLE_THRESHOLDS = {
  mode: ThresholdsMode.Absolute,
  steps: [
    { color: 'grey', value: -Infinity },
    { color: 'grey', value: 0 },
    { color: 'dark-red', value: 1 },
  ],
};
