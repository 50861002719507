import { Field, Text, Tooltip, Stack, Label, Icon, MultiSelect } from '@grafana/ui';
import { Severity } from '__generated__/graphql';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { SlackAlertConfig } from '../types';

export const SEVERITY_OPTIONS = [
  { label: 'Critical', value: Severity.Critical },
  { label: 'High', value: Severity.High },
  { label: 'Medium', value: Severity.Medium },
  { label: 'Low', value: Severity.Low },
];

interface SlackSeveritiesFieldType<T extends SlackAlertConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const SlackSeveritiesField = <T extends SlackAlertConfig>({
  control,
  formState,
  required,
  disabled,
}: SlackSeveritiesFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Severities*</span>
            <Tooltip content={<Text element="p">Which levels of severity to alert on</Text>}>
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'slackSeverities' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <MultiSelect
            options={SEVERITY_OPTIONS}
            {...field}
            onChange={(value) => field.onChange(value)}
            value={field.value}
            width={40}
            invalid={!!formState.errors.slackSeverities}
            disabled={disabled}
          />
        )}
      />
    </Field>
  );
};
