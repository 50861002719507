import { useSuspenseQuery } from '@apollo/client';
import { GetScanningConfigQuery, Source } from '__generated__/graphql';

import { ManageContainerScanning } from './ManageContainerScanning';
import { ManageRepositoryScanning } from './ManageRepositoryScanning';
import { GET_SCANNING_CONFIG } from './ManageSourceQueries';

const CONTAINER = 'container';

interface ManageScanningType {
  sourceID: string;
}

export const ManageScanning = ({ sourceID }: ManageScanningType) => {
  const {
    data: { source },
  } = useSuspenseQuery<GetScanningConfigQuery>(GET_SCANNING_CONFIG, {
    variables: {
      id: sourceID,
    },
  });

  return (
    <>
      {source.type.toLowerCase() === CONTAINER ? (
        <ManageContainerScanning source={source as Source} />
      ) : (
        <ManageRepositoryScanning source={source as Source} />
      )}
    </>
  );
};
