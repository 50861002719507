import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { BigValueColorMode, BigValueTextMode } from '@grafana/schema';

export const getCveTotalsStat = ({ title }: { title: string }) =>
  PanelBuilders.stat()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'rowsToFields',
            options: {},
          },
          {
            id: 'organize',
            options: {
              excludeByName: {
                UNKNOWN: true,
              },
              indexByName: {
                critical: 0,
                high: 1,
                medium: 2,
                low: 3,
              },
              renameByName: {
                critical: 'Critical',
                high: 'High',
                medium: 'Medium',
                low: 'Low',
              },
            },
          },
        ],
      })
    )
    .setTitle(title)
    .setDisplayMode('transparent')
    .setOption('colorMode', BigValueColorMode.Background)
    .setOption('textMode', BigValueTextMode.ValueAndName)
    .setOption('wideLayout', false)
    .setNoValue('No CVEs')
    .setColor({
      mode: 'fixed',
      fixedColor: 'green',
    })
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('Critical').overrideColor({
        mode: 'fixed',
        fixedColor: 'red',
      });
      overridesBuilder.matchFieldsWithName('High').overrideColor({
        mode: 'fixed',
        fixedColor: 'orange',
      });
      overridesBuilder.matchFieldsWithName('Medium').overrideColor({
        mode: 'fixed',
        fixedColor: 'yellow',
      });
      overridesBuilder.matchFieldsWithName('Low').overrideColor({
        mode: 'fixed',
        fixedColor: 'grey',
      });
    })
    .build();
