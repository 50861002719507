export const testIds = {
  appConfig: {
    container: 'data-testid ac-container',
    apiKey: 'data-testid ac-api-key',
    apiUrl: 'data-testid ac-api-url',
    submit: 'data-testid ac-submit-form',
    intigritiApiKey: 'data-testid ac-intigriti-api-key',
  },
  scans: {
    container: 'data-testid scans-two-container',
  },
  issues: {
    container: 'data-testid issue-two-container',
  },
  cves: {
    container: 'data-testid sve-two-container',
  },
  integrations: {
    container: 'data-testid integrations-container',
    navigateBack: 'data-testid navigate-back',
  },
  licenses: {
    container: 'data-testid license-preview-container',
  },
};
