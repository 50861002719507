import { getAppEvents } from '@grafana/runtime';
import {
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneAppPageLike,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide, VariableSort } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { ShowBasedOnConditionBehavior } from 'scenes/behaviors/ShowBasedOnConditionBehavior';
import { K8sClusterHeader } from 'scenes/components/K8sClusterHeader';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { K8s } from 'scenes/customScenes/K8s';
import { baseOpts, getInfinityDatasource, urlOpts } from 'scenes/datasources/infinity';
import { K8S_SCANNING_ROUTE } from 'shared/constants';
import { K8sCardNamespaceClickedEvent } from 'shared/events';

import { getCveTotalsStat } from './panels';
import { getClusterCveCountsQueryRunner, getNamespaceCveCountsQueryRunner } from './queryRunners';

interface GetK8sClusterDrilldownPageType {
  apiUrl: string;
  clusterID: string;
  parent: SceneAppPageLike;
}

export const getK8sClusterDrilldown = ({ apiUrl, clusterID, parent }: GetK8sClusterDrilldownPageType) => {
  const namespaceVariable = new QueryVariable({
    key: 'namespace',
    name: 'namespace',
    label: 'Namespace',
    datasource: getInfinityDatasource(),
    sort: VariableSort.alphabeticalCaseInsensitiveAsc,
    includeAll: true,
    defaultToAll: true,
    allValue: '0',
    query: {
      refId: 'variable-namespace',
      infinityQuery: {
        ...baseOpts,
        url: apiUrl,
        root_selector: 'data.k8sCluster.namespaces',
        url_options: {
          ...urlOpts,
          body_graphql_query: `
            query getNamespaces($id: ID!) {
              k8sCluster(id: $id) {
                namespaces {
                  __value: id
                  __text: name
                }
              }
            }`,
          body_graphql_variables: JSON.stringify({
            id: clusterID,
          }),
        },
      },
      queryType: 'infinity',
    },
  });

  const imageVariable = new TextInputVariable({
    name: 'image',
    prefix: <Icon name="gf-layout-simple" />,
    placeholder: 'Search Images',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  getAppEvents().subscribe(K8sCardNamespaceClickedEvent, ({ payload }) => {
    namespaceVariable.changeValueTo(payload.id, payload.name);
  });

  return new SceneAppPage({
    title: 'Cluster Scan',
    renderTitle: () => K8sClusterHeader({ clusterID }),
    key: 'k8sScanningDrilldown',
    url: `${K8S_SCANNING_ROUTE}/${clusterID}`,
    getParentPage: () => parent,
    getScene: () => {
      return new EmbeddedScene({
        key: 'k8sScanning',
        $variables: new SceneVariableSet({
          variables: [namespaceVariable, imageVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'row',
              width: '100%',
              height: '110px',
              children: [
                new SceneFlexItem({
                  $data: getClusterCveCountsQueryRunner(apiUrl, clusterID),
                  body: getCveTotalsStat({ title: 'Cluster CVE Totals' }),
                }),
                new SceneFlexItem({
                  $data: getNamespaceCveCountsQueryRunner(apiUrl),
                  body: getCveTotalsStat({ title: 'Namespace CVE Totals' }),
                  $behaviors: [
                    new ShowBasedOnConditionBehavior({
                      references: ['namespace'],
                      condition: (namespace: QueryVariable) => namespace.state.value !== '$__all',
                    }),
                  ],
                }),
              ],
            }),
            new K8s({
              cluster: clusterID,
              namespace: '${namespace}',
              image: '${image}',
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
  });
};
