import { useQuery } from '@apollo/client';
import { Box, Stack, Text } from '@grafana/ui';
import { GetIssueHeaderQuery } from '__generated__/graphql';

import { getSourceImage } from '../utils';

import { GET_ISSUE_HEADER } from './IssueHeaderQueries';

interface IssueHeaderType {
  issueID: string;
}

export const IssueHeader = ({ issueID }: IssueHeaderType) => {
  const { data, loading, error } = useQuery<GetIssueHeaderQuery>(GET_ISSUE_HEADER, {
    variables: {
      id: issueID,
    },
  });

  if (loading || error) {
    return null;
  }

  const { cve, version, package: pkg } = data!.issue;
  const capitalizeTitle = (title: string) => title.charAt(0).toUpperCase() + title.slice(1);
  const sourceImage = getSourceImage(version.source.origin);

  return (
    <Stack direction="column" gap={1}>
      <Text element="h1">
        {cve.cve} (
        <Box marginLeft={0.25} marginRight={1} display="inline" justifyContent="center">
          <img src={sourceImage} alt="logo" height="30px" width="30px" />
        </Box>
        {version.source.name}:{version.tag})
      </Text>
      <Text element="h4" color="secondary">
        {capitalizeTitle(cve.title)} in {pkg}
      </Text>
    </Stack>
  );
};
