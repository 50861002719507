import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, Icon, Stack, Text, TextLink, Tooltip, useStyles2, useTheme2 } from '@grafana/ui';
import { GetSourceWithCvesQuery, Severity } from '__generated__/graphql';
import { CVES_ROUTE, SOURCES_ROUTE } from 'shared/constants';

import { BaseTable } from '../base';
import { capitalizeFirstLetter, looseSemverSortTagsDec } from '../utils';

import { GET_SOURCE_WITH_CVES } from './CompareQueries';

interface CompareType {
  sourceID: string;
  cve: string;
  severity: string;
}

export const Compare = ({ sourceID, cve, severity }: CompareType) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  const { data } = useSuspenseQuery<GetSourceWithCvesQuery>(GET_SOURCE_WITH_CVES, {
    variables: {
      id: sourceID,
    },
  });

  const versions: { [tag: string]: string } = {};
  const cveSeverities: { [cve: string]: Severity } = {};
  const cveMatrix: { [cve: string]: { [version: string]: boolean } } = {};

  data.source.versions.forEach((version) => {
    if (!(version.tag in versions)) {
      versions[version.tag] = version.id;
    }

    version.issues.forEach((issue) => {
      if (issue.isDismissed) {
        return;
      }

      const cveKey = issue.cve.cve as Severity;

      if (!cveMatrix[cveKey]) {
        cveMatrix[cveKey] = {};
        cveSeverities[cveKey] = issue.cve.severity as Severity;
      }

      cveMatrix[cveKey][version.tag] = true;
    });
  });

  const sortedTags = looseSemverSortTagsDec(Object.keys(versions));

  const filteredCves = Object.entries(cveMatrix).filter(([fullCve, _]) => {
    const substringMatch = fullCve.toLowerCase().includes(cve.toLowerCase());
    const sevMatch = severity === 'All' || cveSeverities[fullCve].toLowerCase() === severity.toLowerCase();
    return substringMatch && sevMatch;
  });

  const sortedCves = filteredCves.sort((a, b) => {
    const severityOrder = {
      [Severity.Critical]: 1,
      [Severity.High]: 2,
      [Severity.Medium]: 3,
      [Severity.Low]: 4,
      [Severity.Unknown]: 5,
    };

    const sevA = cveSeverities[a[0]];
    const sevB = cveSeverities[b[0]];

    if (severityOrder[sevA] < severityOrder[sevB]) {
      return -1;
    }

    if (severityOrder[sevB] < severityOrder[sevA]) {
      return 1;
    }

    return 0;
  });

  return (
    <Stack direction="column">
      {sortedCves.length ? (
        <div className={styles.scrollContainer}>
          <BaseTable theme={theme}>
            <thead>
              <tr>
                <th className={styles.cveCol}>CVE</th>
                {sortedTags.map((tag, index) => (
                  <th key={index} className={styles.tags}>
                    <TextLink
                      href={`${SOURCES_ROUTE}/${sourceID}/version/${versions[tag]}`}
                      inline={false}
                      weight="bold"
                    >
                      {tag}
                    </TextLink>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedCves.map(([cve, tags], index) => (
                <tr key={index}>
                  <td>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start">
                      <TextLink href={`${CVES_ROUTE}/details/${cve}`} inline={false} weight="bold">
                        {cve}
                      </TextLink>
                      <span className={styles.getSeverityColor(cveSeverities[cve])}>
                        <Text italic>({capitalizeFirstLetter(cveSeverities[cve])})</Text>
                      </span>
                    </Stack>
                  </td>
                  {sortedTags.map((tag, index) => (
                    <td className={styles.tags} key={index}>
                      {tags[tag] ? (
                        <Text color="error">
                          <Tooltip
                            content={
                              <Text element="p">
                                {cve} detected in {tag}
                              </Text>
                            }
                          >
                            <Icon name="minus-circle" size="lg" />
                          </Tooltip>
                        </Text>
                      ) : (
                        <Text color="disabled">
                          <Icon name="minus" size="lg" />
                        </Text>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </BaseTable>
        </div>
      ) : (
        <Box margin={2}>
          <Stack justifyContent="center" alignItems="center">
            <Text italic color="secondary" variant="h5">
              No CVEs
            </Text>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  scrollContainer: css({
    overflowX: 'auto',
    '@media (max-width: 720px)': {
      overflowX: 'scroll',
    },
  }),
  cveCol: css({
    minWidth: '280px',
  }),
  tags: css({
    textAlign: 'center',
    minWidth: '120px',
  }),
  getSeverityColor: (severity: Severity) => {
    switch (severity) {
      case Severity.Critical:
        return css({ color: theme.visualization.getColorByName('red') });
      case Severity.High:
        return css({ color: theme.visualization.getColorByName('orange') });
      case Severity.Medium:
        return css({ color: theme.visualization.getColorByName('yellow') });
      case Severity.Low:
      default:
        return css({ color: theme.visualization.getColorByName('grey') });
    }
  },
});
