import { Field, Label, Switch } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { GithubAlertConfig } from '../types';

interface GithubSubIssuesFieldType<T extends GithubAlertConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const GithubSubIssuesField = <T extends GithubAlertConfig>({
  control,
  formState,
  required,
  disabled,
}: GithubSubIssuesFieldType<T>) => {
  return (
    <Field label={<Label>Create Sub-Issues{required ? '*' : ''}</Label>}>
      <Controller
        name={'githubCreateSubIssues' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Switch
            {...field}
            invalid={!!formState.errors.githubCreateSubIssues}
            disabled={disabled}
            value={field.value as boolean}
          />
        )}
      />
    </Field>
  );
};
