import { DataLinkClickEvent } from '@grafana/data';
import {
  CustomVariable,
  PanelBuilders,
  SceneCanvasText,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  sceneGraph,
  SplitLayout,
} from '@grafana/scenes';
import { BarGaugeDisplayMode, BarGaugeValueMode, TableCellDisplayMode } from '@grafana/schema';
import { ColorBoxCell } from 'scenes/components/ColorBoxCell';
import { CVSS_SCORE_THRESHOLDS, SEVERITY_MAPPINGS, SLO_REMAINING_THRESHOLDS } from 'scenes/panelConstants';

import { getRelatedIssuesTable } from '../panels';
import { getCvesWithSloQueryRunner } from '../queryRunners/getCvesWithSloQueryRunner';

export const getCveSplitLayout = (apiUrl: string, versionID: string) => {
  const cveUrlParam = new URLSearchParams(window.location.search).get('var-cve');

  const cveSplitLayout = new SplitLayout({
    direction: 'row',
    initialSize: 0.35,
    $data: getCvesWithSloQueryRunner(apiUrl, versionID),
    primary: new SceneFlexItem({
      // This table references the splitter scene object and
      // can't be trivially pulled out to its own function
      body: PanelBuilders.table()
        .setData(
          new SceneDataTransformer({
            transformations: [
              {
                id: 'groupBy',
                options: {
                  fields: {
                    cve: {
                      operation: 'groupby',
                    },
                    sloRemaining: {
                      aggregations: ['min'],
                      operation: 'aggregate',
                    },
                    severity: {
                      operation: 'groupby',
                    },
                    cvssScore: {
                      operation: 'groupby',
                    },
                  },
                },
              },
              {
                id: 'organize',
                options: {
                  indexByName: {
                    cve: 0,
                    'sloRemaining (min)': 1,
                    severity: 2,
                    cvssScore: 3,
                  },
                  renameByName: {
                    cve: 'CVE',
                    severity: 'Severity',
                    cvssScore: 'CVSS',
                    'sloRemaining (min)': 'SLO Remaining',
                  },
                },
              },
              {
                id: 'sortBy',
                options: {
                  sort: [
                    {
                      field: 'SLO Remaining',
                      desc: false,
                    },
                  ],
                },
              },
            ],
          })
        )
        .setTitle('Detected CVEs')
        .setCustomFieldConfig('align', 'left')
        .setThresholds(SLO_REMAINING_THRESHOLDS)
        .setNoValue('No CVEs')
        .setMin(0)
        .setMax(60)
        .setOverrides((overridesBuilder) => {
          overridesBuilder
            .matchFieldsWithName('CVE')
            .overrideCustomFieldConfig('filterable', true)
            .overrideCustomFieldConfig('width', 200)
            .overrideNoValue('No CVE')
            .overrideLinks([
              {
                title: 'See risks for ${__data.fields.CVE}',
                url: '',
                onClick: (e: DataLinkClickEvent) => {
                  let cve = e.origin.field.values.get(e.origin.rowIndex);
                  if (cve === '') {
                    cve = 'No CVE';
                  }

                  const cveVariable = sceneGraph.lookupVariable('cve', cveSplitLayout) as CustomVariable;
                  cveVariable.setState({ value: cve });

                  cveSplitLayout.setState({
                    secondary: new SceneFlexItem({
                      minWidth: 500,
                      body: getRelatedIssuesTable(cve),
                    }),
                  });
                },
              },
            ]);
          overridesBuilder
            .matchFieldsWithName('SLO Remaining')
            .overrideCustomFieldConfig('cellOptions', {
              type: TableCellDisplayMode.Gauge,
              mode: BarGaugeDisplayMode.Basic,
              valueDisplayMode: BarGaugeValueMode.Text,
            })
            .overrideUnit('days');

          overridesBuilder
            .matchFieldsWithName('Severity')
            .overrideCustomFieldConfig('align', 'center')
            .overrideCustomFieldConfig('width', 110)
            .overrideCustomFieldConfig('cellOptions', {
              type: TableCellDisplayMode.ColorText,
            })
            .overrideMappings(SEVERITY_MAPPINGS);

          overridesBuilder
            .matchFieldsWithName('CVSS')
            .overrideCustomFieldConfig('align', 'center')
            .overrideCustomFieldConfig('width', 80)
            .overrideDecimals(1)
            .overrideNoValue('0.0')
            .overrideCustomFieldConfig('cellOptions', {
              // @ts-ignore
              type: 'custom',
              cellComponent: ColorBoxCell,
            })
            .overrideThresholds(CVSS_SCORE_THRESHOLDS);
        })
        .build(),
      minWidth: 300,
    }),
    secondary: new SceneFlexItem({
      minWidth: 500,
      body:
        cveUrlParam && cveUrlParam.length
          ? new SceneFlexLayout({
              children: [
                new SceneFlexItem({
                  minWidth: 500,
                  body: getRelatedIssuesTable(cveUrlParam),
                }),
              ],
            })
          : new SceneCanvasText({
              text: 'Select a CVE to see risks',
              fontSize: 20,
              align: 'center',
            }),
    }),
  });

  return cveSplitLayout;
};
