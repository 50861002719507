import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { getAppEvents, usePluginInteractionReporter } from '@grafana/runtime';
import { Box, Card, Icon, Stack, TagList, Text, Tooltip, useStyles2 } from '@grafana/ui';
import { Secret } from '__generated__/graphql';
import { SECRET_SEVERITIES, SECRETS_CARD_CLICK, SOURCES_ROUTE } from 'shared/constants';
import { isLevel, SecretCardTagClickedEvent } from 'shared/events';

import { getSourceImage } from '../utils';

interface SecretCardProps {
  secret: Secret;
  simplified?: boolean;
  disable?: boolean;
}

export const SecretCard = ({ secret, simplified, disable }: SecretCardProps) => {
  const styles = useStyles2(getStyles);
  const report = usePluginInteractionReporter();
  const { version, startPreview, startLine, endLine, preview, category, severity, dockerCreatedBy, dockerDiffId } =
    secret;
  const {
    source: { name, id: sourceId },
    id: versionId,
  } = version;
  const sourceImage = getSourceImage(secret.version.source.origin);

  const getSeverity = () => (isLevel(severity) ? severity : 'UNKNOWN');

  const formatSeverity = () => SECRET_SEVERITIES[getSeverity()];

  const getSeverityStyle = (): string => {
    const sev = getSeverity();
    if (sev !== '') {
      return styles[sev];
    }
    return '';
  };

  const onTagClick = () =>
    getAppEvents().publish(
      new SecretCardTagClickedEvent({
        severityValue: getSeverity(),
        severityLabel: formatSeverity(),
      })
    );

  const clickProps = !disable
    ? {
        href: `${SOURCES_ROUTE}/${sourceId}/version/${versionId}`,
        onClick: () => report(SECRETS_CARD_CLICK, { secret_id: secret.id }),
      }
    : {};

  const emptyPreviewMessage = () => (
    <Box display="flex" marginTop={0.5} justifyContent="center" alignItems="center">
      <Icon name="exclamation-triangle" size="lg" className={styles.emptyPreviewIcon} />
      <Text color="secondary"> No source code preview found</Text>
    </Box>
  );

  return (
    <Card
      {...clickProps}
      style={{
        display: 'grid',
        minWidth: '680px',
        gridTemplate: `
        "Figure Heading Tags" 0fr
        "Figure Meta Tags" 0fr
        "Figure Description Tags"
        "Figure Actions Secondary" / auto 1fr auto
        `,
      }}
    >
      {simplified ? (
        <>
          <Card.Figure>
            <Icon name="key-skeleton-alt" size="xxl" />
          </Card.Figure>
          <Card.Heading>{category}</Card.Heading>
          <Card.Meta>{secret.title}</Card.Meta>
        </>
      ) : (
        <>
          <Card.Figure>
            <img src={sourceImage} alt="logo" height="40px" width="40px" className={styles.logo} />
          </Card.Figure>
          <Card.Heading>{`${name}:${version.version}`}</Card.Heading>
          <Card.Meta className={styles.meta} separator="|">
            {category}
            {secret.title}
          </Card.Meta>
        </>
      )}
      <Card.Tags>
        <TagList tags={[formatSeverity()]} onClick={onTagClick} className={getSeverityStyle()} />
      </Card.Tags>
      <div className={styles.codePreview}>
        <Stack direction="column">
          {dockerDiffId && (
            <>
              <div className={styles.codeTitle}>
                <Text>
                  <Tooltip content={dockerDiffId} interactive>
                    <Icon name="docker" size="xl" className={styles.dockerHeader} />
                  </Tooltip>
                  {`Dockerfile - ${dockerDiffId.substring(7, 15)}`}
                </Text>
              </div>
              <Stack direction="row">
                <div className={styles.codeLines}>
                  <pre>
                    <code>
                      {dockerCreatedBy && dockerCreatedBy.length > 0 ? dockerCreatedBy : emptyPreviewMessage()}
                    </code>
                  </pre>
                </div>
              </Stack>
            </>
          )}
          <div className={styles.codeTitle}>
            <Text>
              <Tooltip content={secret.target} interactive>
                <Icon name="folder" size="lg" className={styles.targetHeader} type="mono" />
              </Tooltip>
              {secret.target}
            </Text>
          </div>
          <Stack direction="row">
            <div className={styles.codeLines}>
              <pre>
                <code>
                  {preview && preview.length > 0
                    ? preview.map((line, idx) => {
                        const lineIdx = idx + startPreview;
                        const numberedLine = (
                          <div key={idx} data-testid={`secret-card-line-${lineIdx}`}>
                            <span className={styles.lineNumber}>{`${idx + startPreview}`}</span>
                            {`\t${line}\n`}
                          </div>
                        );
                        if (lineIdx >= startLine && lineIdx <= endLine) {
                          return (
                            <div key={idx} className={styles.highlightedLine}>
                              {numberedLine}
                            </div>
                          );
                        }
                        return numberedLine;
                      })
                    : emptyPreviewMessage()}
                </code>
              </pre>
            </div>
          </Stack>
        </Stack>
      </div>
    </Card>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    logo: css({
      objectFit: 'contain',
    }),
    meta: css({
      flexWrap: 'wrap',
    }),
    CRITICAL: css({
      '& button, span': {
        backgroundColor: theme.visualization.getColorByName('dark-red'),
      },
    }),
    HIGH: css({
      '& button, span': {
        backgroundColor: theme.visualization.getColorByName('dark-orange'),
      },
    }),
    MEDIUM: css({
      '& button, span': {
        backgroundColor: theme.visualization.getColorByName('dark-yellow'),
      },
    }),
    LOW: css({
      '& button, span': {
        backgroundColor: theme.visualization.getColorByName('grey'),
      },
    }),
    UNKNOWN: css({
      '& button, span': {
        backgroundColor: theme.colors.error.main,
      },
    }),
    codePreview: css({
      gridArea: 'Description',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(-2),
      marginRight: theme.spacing(1.5),
      minWidth: '480px',
      maxWidth: '1fr',
    }),
    dockerHeader: css({
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(0.75),
      marginTop: theme.spacing(-0.75),
    }),
    targetHeader: css({
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(-0.5),
    }),
    codeLines: css({
      '& code, pre': {
        backgroundColor: theme.colors.background.canvas,
        display: 'block',
      },
      width: '100%',
    }),
    lineNumber: css({
      color: theme.colors.text.secondary,
    }),
    emptyPreviewIcon: css({
      color: theme.colors.warning.main,
    }),
    highlightedLine: css({
      color: theme.colors.error.main,
    }),
    codeTitle: css({
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(0.75),
      paddingRight: theme.spacing(0.5),
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.colors.border.medium,
      backgroundColor: theme.colors.background.primary,
      marginBottom: theme.spacing(-2),
      zIndex: 1,
      borderTopLeftRadius: theme.spacing(0.75),
      borderTopRightRadius: theme.spacing(0.75),
      color: theme.colors.text.maxContrast,
      fontSize: theme.spacing(1.5),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
  };
};
