import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { TableCellDisplayMode, ThresholdsMode } from '@grafana/schema';
import { GroupsCell } from 'scenes/components/GroupsCell';
import { SourceTypeCell } from 'scenes/components/IssuesTable';
import { SECRET_SLO_TABLE_THRESHOLDS, SLO_STATUS_MAPPINGS } from 'scenes/panelConstants';
import { removeSubstringTransform } from 'scenes/transformations';
import { GITHUB_PREFIX, SOURCES_ROUTE } from 'shared/constants';

export const getSourcesWithSloTable = () =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'sortBy',
            options: {
              sort: [
                {
                  field: 'lowestSloRemaining',
                },
              ],
            },
          },
          removeSubstringTransform({
            substring: GITHUB_PREFIX,
            fieldName: 'name',
          }),
          {
            id: 'organize',
            options: {
              indexByName: {
                type: 0,
                name: 1,
                groups: 2,
                totalSecrets: 3,
                totalLicenses: 4,
                critical: 5,
                high: 6,
                medium: 7,
                low: 8,
                lowestSloRemaining: 9,
              },
              renameByName: {
                type: 'Type',
                name: 'Source',
                groups: 'Groups',
                critical: 'Critical',
                high: 'High',
                medium: 'Medium',
                low: 'Low',
                lowestSloRemaining: 'SLO Compliance',
                totalSecrets: 'Exposed Secrets',
                totalLicenses: 'License Violations',
              },
            },
          },
        ],
      })
    )
    .setTitle('SLO Compliance By Source')
    .setDisplayMode('transparent')
    .setThresholds({
      mode: ThresholdsMode.Absolute,
      steps: [
        { color: 'dark-red', value: -Infinity },
        { color: 'dark-orange', value: 1 },
        { color: 'dark-green', value: 7 },
      ],
    })
    .setNoValue('No Sources')
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('type')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: SourceTypeCell,
        })
        .overrideCustomFieldConfig('width', 50);
      overridesBuilder
        .matchFieldsWithName('Source')
        .overrideLinks([
          {
            title: 'See ${__data.fields.source}',
            url: `${SOURCES_ROUTE}/\${__data.fields.id}`,
          },
        ])
        .overrideCustomFieldConfig('filterable', true);
      overridesBuilder.matchFieldsWithName('Groups').overrideCustomFieldConfig('cellOptions', {
        // @ts-ignore
        type: 'custom',
        cellComponent: GroupsCell,
      });

      overridesBuilder
        .matchFieldsWithName('Critical')
        .overrideCustomFieldConfig('width', 90)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'red', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('High')
        .overrideCustomFieldConfig('width', 90)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'orange', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('Medium')
        .overrideCustomFieldConfig('width', 90)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'yellow', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('Low')
        .overrideCustomFieldConfig('width', 90)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'text', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('SLO Compliance')
        .overrideCustomFieldConfig('width', 200)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideMappings(SLO_STATUS_MAPPINGS);

      overridesBuilder
        .matchFieldsWithName('Exposed Secrets')
        .overrideCustomFieldConfig('width', 150)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideThresholds(SECRET_SLO_TABLE_THRESHOLDS);

      overridesBuilder
        .matchFieldsWithName('License Violations')
        .overrideCustomFieldConfig('width', 150)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideThresholds(SECRET_SLO_TABLE_THRESHOLDS);
      overridesBuilder.matchFieldsWithName('id').overrideCustomFieldConfig('hidden', true);
    })
    .build();
