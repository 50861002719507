import { getAppEvents } from '@grafana/runtime';
import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexLayout,
  SceneVariableSet,
  QueryVariable,
  VariableValueSelectors,
  SceneFlexItem,
  CustomVariable,
  SceneReactObject,
  sceneGraph,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon, LinkButton } from '@grafana/ui';
import { SourceType } from '__generated__/graphql';
import { ActWhenVariablesChanged } from 'scenes/controls/ActWhenVariablesChanged';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { Paginator } from 'scenes/customScenes/Paginator';
import { Sources } from 'scenes/customScenes/Sources';
import { getInfinityDatasource, baseOpts, urlOpts } from 'scenes/datasources/infinity';
import { CREATE_SOURCE_ROUTE, SOURCES_ROUTE } from 'shared/constants';
import { SourceCardGroupClickedEvent } from 'shared/events';

import { getSourceDrilldown } from './getSourceDrilldown';
import { getSourcesCountQueryRunner } from './queryRunners';

interface GetSourcesPageType {
  apiUrl: string;
}

export const getSourcesPage = ({ apiUrl }: GetSourcesPageType) => {
  const groupVariable = new QueryVariable({
    name: 'group',
    label: 'Group',
    includeAll: true,
    defaultToAll: true,
    allValue: '0',
    datasource: getInfinityDatasource(),
    query: {
      refId: 'variable-group',
      infinityQuery: {
        ...baseOpts,
        url: apiUrl,
        root_selector: 'data.groups',
        url_options: {
          ...urlOpts,
          body_graphql_query: `
            query getGroups {
              groups {
                __value: id
                __text: name
              }
            }
          `,
        },
      },
      queryType: 'infinity',
    },
  });

  getAppEvents().subscribe(SourceCardGroupClickedEvent, ({ payload }) => {
    groupVariable.changeValueTo(payload.groupID, payload.groupName);
  });

  return new SceneAppPage({
    title: 'Explore Sources',
    subTitle: 'Browse actively scanned projects',
    key: 'sourcesPage',
    url: SOURCES_ROUTE,
    controls: [
      new SceneReactObject({
        component: () => (
          <LinkButton variant="primary" size="sm" href={CREATE_SOURCE_ROUTE}>
            Configure a New Source
          </LinkButton>
        ),
      }),
    ],
    getScene: () => {
      return new EmbeddedScene({
        key: 'sources',
        $variables: new SceneVariableSet({
          variables: [
            new TextInputVariable({
              name: 'source',
              prefix: <Icon name="search" />,
              placeholder: 'Search sources',
              width: 40,
              hide: VariableHide.hideLabel,
            }),
            new CustomVariable({
              name: 'type',
              label: 'Type',
              value: SourceType.All,
              text: 'All',
              query: `All : ${SourceType.All},Container : ${SourceType.Container},Repository : ${SourceType.Repository}`,
            }),
            groupVariable,
            new CustomVariable({
              name: 'sourceFirst',
              value: 24,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '12',
            }),
            new CustomVariable({
              name: 'sourceAfter',
              value: 0,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '0',
            }),
          ],
        }),
        $behaviors: [
          new ActWhenVariablesChanged({
            variableNames: ['source', 'type', 'group'],
            onChange: (variable) => {
              const after = sceneGraph.lookupVariable('sourceAfter', variable) as CustomVariable;
              after.setState({ value: 0 });
            },
          }),
        ],
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              height: '100%',
              body: new Sources({
                source: '${source}',
                type: '${type}',
                group: '${group}',
                sourceFirst: '${sourceFirst}',
                sourceAfter: '${sourceAfter}',
              }),
            }),
            new SceneFlexItem({
              $data: getSourcesCountQueryRunner(apiUrl),
              body: new Paginator({
                first: '${sourceFirst}',
                after: '${sourceAfter}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
    drilldowns: [
      {
        routePath: `${SOURCES_ROUTE}/:sourceID`,
        getPage(routeMatch, parent) {
          const sourceID = routeMatch.params.sourceID;
          return getSourceDrilldown({ apiUrl, sourceID, parent });
        },
      },
    ],
  });
};
