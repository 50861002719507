import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getCveCountsPerClusterQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'A',
        url: apiUrl,
        root_selector: 'data.k8sClusters.response',
        columns: [
          {
            selector: 'id',
            text: 'id',
            type: 'string',
          },
          {
            selector: 'name',
            text: 'Cluster',
            type: 'string',
          },
          {
            selector: 'totalCveCounts.critical',
            text: 'Critical',
            type: 'number',
          },
          {
            selector: 'totalCveCounts.high',
            text: 'High',
            type: 'number',
          },
          {
            selector: 'totalCveCounts.medium',
            text: 'Medium',
            type: 'number',
          },
          {
            selector: 'totalCveCounts.low',
            text: 'Low',
            type: 'number',
          },
        ],
        url_options: {
          body_graphql_query: `
              query getCveCountsPerCluster($filters: K8sClusterFilters) {
                k8sClusters(filters: $filters) {
                  response {
                    id
                    name
                    totalCveCounts {
                      critical
                      high
                      medium
                      low
                    }
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              name: '${name}',
            },
          }),
        },
      },
    ])
  );
