import { Version } from '__generated__/graphql';
import { gt } from 'semver';

const getSemverLike = (version: string) => {
  const semverRegex = /(\d+\.\d+\.\d+)/g;
  const matches = version.match(semverRegex);
  return matches ? matches[0] : null;
};

export const looseSemverSort = (versions: Version[]): Version[] => {
  return versions.slice().sort((a, b) => {
    if (a.version.toLowerCase().includes('latest') || a.version.toLowerCase().includes('main')) {
      return -1;
    } else if (b.version.toLowerCase().includes('latest') || b.version.toLowerCase().includes('main')) {
      return 1;
    }

    const aSemver = getSemverLike(a.version);
    const bSemver = getSemverLike(b.version);
    if (!aSemver || !bSemver) {
      return 0;
    }

    return gt(aSemver, bSemver) ? -1 : 1;
  });
};

export const looseSemverSortTagsDec = (tags: string[]): string[] => {
  return tags.slice().sort((a, b) => {
    if (a.toLowerCase().includes('latest') || a.toLowerCase().includes('main')) {
      return -1;
    } else if (b.toLowerCase().includes('latest') || b.toLowerCase().includes('main')) {
      return 1;
    }

    const aSemver = getSemverLike(a);
    const bSemver = getSemverLike(b);
    if (!aSemver || !bSemver) {
      return 0;
    }

    return gt(aSemver, bSemver) ? -1 : 1;
  });
};
