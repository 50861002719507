import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { K8S_SCANNING_ROUTE } from 'shared/constants';

import { getK8sClusterDrilldown } from './getK8sClusterDrilldown';
import { getClusterCvesTable } from './panels';
import { getCveCountsPerClusterQueryRunner } from './queryRunners';

interface GetK8sScanningPageType {
  apiUrl: string;
}

export const getK8sScanningPage = ({ apiUrl }: GetK8sScanningPageType) => {
  const nameVariable = new TextInputVariable({
    name: 'name',
    placeholder: 'Search Clusters',
    prefix: <Icon name="search" />,
    width: 40,
    hide: VariableHide.hideLabel,
  });

  return new SceneAppPage({
    title: 'Kubernetes Scanning',
    subTitle: 'Explore versions currently running in your K8s infrastructure',
    key: 'k8sScanningPage',
    url: K8S_SCANNING_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'k8sScanning',
        $variables: new SceneVariableSet({
          variables: [nameVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'column',
              width: '100%',
              height: '100%',
              children: [
                new SceneFlexItem({
                  $data: getCveCountsPerClusterQueryRunner(apiUrl),
                  body: getClusterCvesTable(),
                }),
              ],
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
    drilldowns: [
      {
        routePath: `${K8S_SCANNING_ROUTE}/:clusterID`,
        getPage(routeMatch, parent) {
          const clusterID = routeMatch.params.clusterID;
          return getK8sClusterDrilldown({ apiUrl, clusterID, parent });
        },
      },
    ],
  });
};
