import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { ConfigureSource } from 'scenes/components/ConfigureSource';
import { SETTINGS_ROUTE } from 'shared/constants';

interface GetConfigureSourceTabType {
  apiUrl: string;
}

export const getConfigureSourceTab = ({ apiUrl }: GetConfigureSourceTabType) =>
  new SceneAppPage({
    title: 'Configure a New Source',
    titleIcon: 'file-edit-alt',
    key: 'configureSourceTab',
    url: `${SETTINGS_ROUTE}/configure-source`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'configureSource',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneReactObject({
              component: () => <ConfigureSource />,
            }),
          ],
        }),
      });
    },
  });
