import {
  REPOSITORIES,
  DOCKER_LOGO_SVG,
  GAR_LOGO_SVG,
  GCR_LOGO_SVG,
  GITHUB_LOGO_SVG,
  K8S_LOGO_SVG,
  ECR_LOGO_SVG,
  REDHAT_LOGO_SVG,
  REDPANDA_LOGO_SVG,
  UPBOUND_LOGO_SVG,
} from 'shared/constants';

export const getSourceImage = (origin: string): string => {
  const lcOrigin = origin.toLowerCase();
  switch (lcOrigin) {
    case REPOSITORIES.GITHUB:
    case REPOSITORIES.GHCR_IO:
      return GITHUB_LOGO_SVG;
    case REPOSITORIES.DOCKER_HUB:
    case REPOSITORIES.DOCKER_IO:
      return DOCKER_LOGO_SVG;
    case REPOSITORIES.GCR_GLOBAL:
    case REPOSITORIES.GCR_US:
      return GCR_LOGO_SVG;
    case REPOSITORIES.GAR_US:
      return GAR_LOGO_SVG;
    case REPOSITORIES.K8S_REGISTRY:
      return K8S_LOGO_SVG;
    case REPOSITORIES.AWS_ECR:
      return ECR_LOGO_SVG;
    case REPOSITORIES.QUAY_IO:
      return REDHAT_LOGO_SVG;
    case REPOSITORIES.UPBOUND_XPKG:
      return UPBOUND_LOGO_SVG;
    case REPOSITORIES.REDPANDA_DOCKER:
      return REDPANDA_LOGO_SVG;
    default:
      return '';
  }
};
