import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useDeferredValue } from 'react';

import { SecretCardGrid } from './SecretCardGrid';

interface SecretCardGridType {
  versionId: string;
  category: string;
  severity: string;
  first: string;
  after: string;
  target: string;
  type: string;
  simplified: boolean;
}

export const SecretCardGridContainer = ({
  versionId,
  category,
  severity,
  target,
  first,
  after,
  type,
  simplified,
}: SecretCardGridType) => {
  const deferredVersionId = useDeferredValue(versionId);
  const deferredCategory = useDeferredValue(category);
  const deferredSeverity = useDeferredValue(severity);
  const deferredTarget = useDeferredValue(target);
  const deferredType = useDeferredValue(type);
  const deferredFirst = useDeferredValue(first);
  const deferredAfter = useDeferredValue(after);
  const deferredSimplified = useDeferredValue(simplified);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load secrets"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <SecretCardGrid
              versionId={deferredVersionId}
              category={deferredCategory}
              severity={deferredSeverity}
              target={deferredTarget}
              type={deferredType}
              first={deferredFirst}
              after={deferredAfter}
              simplified={deferredSimplified}
            />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
