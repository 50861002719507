import { Field, Label, Stack, Tooltip, Text, Icon, TagsInput } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { RepositoryScanningConfig } from '../types';

interface ExcludedPathsFieldType<T extends RepositoryScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const ExcludedPathsField = <T extends RepositoryScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: ExcludedPathsFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Excluded Paths{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={
                <Text element="p">
                  We use Snyk and Trivy CLI to scan and while Trivy will exclude every folder, Snyk, because of the
                  licensing we have with them, will only exlude folders that are in the root directory. For example, the
                  folder operations/pyroscope/jsonnet/pyroscope-mixin/vendor will NOT be ignored by Snyk.
                </Text>
              }
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'excludedPaths' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <TagsInput
            placeholder="Add path (enter key to add)"
            tags={field.value as string[]}
            onChange={(tags) => field.onChange(tags)}
            width={40}
            disabled={disabled}
            invalid={!!formState.errors.excludedPaths}
          />
        )}
      />
    </Field>
  );
};
