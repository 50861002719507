import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getSecretsCountQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'J',
        url: apiUrl,
        root_selector: 'data.secrets',
        url_options: {
          body_graphql_query: `
              query getSecrets($filters: SecretFilters, $first: Int, $after: Int) {
                secrets(filters: $filters, first: $first, after: $after) {
                  metadata {
                    totalCount
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              category: '$category',
              severity: '$severity',
              target: '$target',
              versionId: '$versionId',
            },
            first: '$secretFirst',
            after: '$secretAfter',
          }),
        },
      },
    ])
  );
