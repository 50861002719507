import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getTotalSecretsCountsQueryRunner = (apiUrl: string, versionId?: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'K',
        url: apiUrl,
        root_selector: 'data.secrets',
        url_options: {
          body_graphql_query: `
              query getSecrets($filters: SecretFilters, $first: Int, $after: Int) {
                secrets(filters: $filters, first: $first, after: $after) {
                  metadata {
                    totalCount
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              type: 'ALL',
              ...(versionId?.length && { versionId }),
            },
          }),
        },
      },
    ])
  );
