import { renderMarkdown } from '@grafana/data';
import { LinkButton, Box } from '@grafana/ui';

interface IssueSummaryType {
  description: string;
  url: string;
  cve: string;
}

export const IssueSummary = ({ description, url, cve }: IssueSummaryType) => {
  return (
    <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" marginBottom={2} padding={1.5}>
      <span
        className="markdown-html"
        // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
        dangerouslySetInnerHTML={{ __html: renderMarkdown(description) }}
      />
      <Box display="flex" justifyContent="right" marginTop={2}>
        <LinkButton href={url} target="_blank" rel="noreferrer" variant="primary" icon="external-link-alt">
          {url && url.includes('github.com') ? 'View on GitHub' : 'View on AVD'}
        </LinkButton>
        {cve && !cve.includes('GHSA') && (
          <Box marginLeft={2}>
            <LinkButton
              href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${cve}`}
              target="_blank"
              rel="noreferrer"
              variant="primary"
              icon="external-link-alt"
            >
              View on Mitre
            </LinkButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
