import { Field, Label, Stack, Tooltip, Text, Icon, Input } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { RepositoryScanningConfig } from '../types';

interface TagPrefixFieldType<T extends RepositoryScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const TagPrefixField = <T extends RepositoryScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: TagPrefixFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Tag Prefix{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={
                <Text element="p">Use if the project does not follow semver tagging (ex: gel-, gem-, get-)</Text>
              }
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'tagPrefix' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value as string}
            width={30}
            disabled={disabled}
            invalid={!!formState.errors.tagPrefix}
          />
        )}
      />
    </Field>
  );
};
