import { Field, Label, Stack, Tooltip, Text, Icon, Switch } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { ContainerScanningConfig } from '../types';

interface UseSemverPatternFieldType<T extends ContainerScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const UseSemverPatternField = <T extends ContainerScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: UseSemverPatternFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>
              Use Semver pattern{required ? '* ' : ' '}
              <Text color="secondary" italic>
                (coming soon)
              </Text>
            </span>
            <Tooltip
              placement="right-end"
              content={<Text element="p">Select number of major, minor and patch versions to scan</Text>}
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={`useSemverPattern` as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Switch
            {...field}
            value={field.value as boolean}
            disabled={disabled}
            invalid={!!formState.errors.useSemverPattern}
          />
        )}
      />
    </Field>
  );
};
