import { renderMarkdown } from '@grafana/data';
import { LinkButton, Box, Stack, Text } from '@grafana/ui';
import { CVE_DETAILS_ROUTE, ISSUES_ROUTE } from 'shared/constants';

interface ExceptionDetailsDescriptionType {
  reason: string;
  description: string;
  cve: string;
}

export const ExceptionDetailsDescription = ({ reason, description, cve }: ExceptionDetailsDescriptionType) => {
  return (
    <Stack direction="column" grow={1}>
      <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" padding={1.5}>
        <Box marginBottom={1}>
          <Text element="h2">{reason}</Text>
        </Box>
        <span
          className="markdown-html"
          // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
          dangerouslySetInnerHTML={{ __html: renderMarkdown(description) }}
        />
        <Box display="flex" justifyContent="right" marginTop={2} gap={2}>
          <LinkButton href={`${CVE_DETAILS_ROUTE}/${cve}`} variant="primary">
            Go to CVE
          </LinkButton>
          <LinkButton href={`${ISSUES_ROUTE}?var-cve=${cve}`} variant="primary">
            Go to Issues
          </LinkButton>
        </Box>
      </Box>
    </Stack>
  );
};
