import { config, usePluginInteractionReporter } from '@grafana/runtime';
import { SceneApp } from '@grafana/scenes';
import { Alert, Box, ErrorBoundaryAlert } from '@grafana/ui';
import { useEffect, useMemo, useState } from 'react';
import { APP_VISIT_EVENT } from 'shared/constants';
import { usePluginMeta } from 'utils/utils.plugin';

import { INFINITY_DATASOURCE_NAME, getInfinityDatasource } from './datasources/infinity';
import { PROMETHEUS_DATASOURCE_NAME, getPrometheusDatasource } from './datasources/prometheus';
import { useRole } from './hooks';
import {
  getAboutPage,
  getBugBountyPage,
  getCvesPage,
  getIssuesPage,
  getK8sScanningPage,
  getPlanReleasePage,
  getSettingsPage,
  getSlosPage,
  getSourcesPage,
  getSecretsScanningPage,
} from './pages';

const getScene = (apiUrl: string) =>
  new SceneApp({
    name: 'security-vulnerability-observability-app',
    pages: [
      getSourcesPage({ apiUrl }),
      getCvesPage({ apiUrl }),
      getK8sScanningPage({ apiUrl }),
      getSecretsScanningPage({ apiUrl }),
      getPlanReleasePage({ apiUrl }),
      getSlosPage({ apiUrl }),
      getBugBountyPage({ apiUrl }),
      getSettingsPage({ apiUrl }),
      getIssuesPage({ apiUrl }),
      getAboutPage({ apiUrl }),
    ],
  });

export const App = () => {
  // Prefetch role to cache the result
  useRole();

  const report = usePluginInteractionReporter();

  const [infinityError, setInfinityError] = useState<string | null>(null);
  const [prometheusError, setPrometheusError] = useState<string | null>(null);
  const pluginMeta = usePluginMeta();
  const apiUrl = `${pluginMeta?.jsonData?.apiUrl}/graphql/query`;

  useEffect(() => {
    if (!getInfinityDatasource()) {
      setInfinityError('Missing Infinity datasource!');
    }

    if (!getPrometheusDatasource()) {
      setPrometheusError('Missing Prometheus datasource!');
    }
  }, []);

  useEffect(() => {
    const user = config.bootData.user;
    report(APP_VISIT_EVENT, {
      id: user.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scene = useMemo(() => getScene(apiUrl), [apiUrl]);

  if (infinityError || prometheusError) {
    return (
      <Box margin={2}>
        {infinityError && (
          <Alert title={infinityError} onRemove={() => window.location.reload()} buttonContent="Reload">
            This app depends on this datasource. Please configure {INFINITY_DATASOURCE_NAME} and reload the page.
          </Alert>
        )}
        {prometheusError && (
          <Alert title={prometheusError} onRemove={() => window.location.reload()} buttonContent="Reload">
            This app depends on this datasource. Please configure {PROMETHEUS_DATASOURCE_NAME} and reload the page.
          </Alert>
        )}
      </Box>
    );
  }

  return (
    <ErrorBoundaryAlert>
      <scene.Component model={scene} />
    </ErrorBoundaryAlert>
  );
};
