import { Box, Stack } from '@grafana/ui';
import { Control, FormState } from 'react-hook-form';
import { REPOSITORIES } from 'shared/constants';

import { ContainerSourceFormType } from './ConfigureContainer';
import {
  AllowedFlavorsField,
  AutoArchiveField,
  BlocklistedFlavorsField,
  ContainerImageNameField,
  LastTagsToScanField,
  ContainerRepositoryField,
  MajorVersionsField,
  MinorVersionsField,
  PatchVersionsField,
  UseSemverPatternField,
} from './Fields';

interface ConfigureContainerScansType {
  control: Control<ContainerSourceFormType>;
  formState: FormState<ContainerSourceFormType>;
  repository: string;
  useSemverPattern: boolean;
}

export const ConfigureContainerScans = ({
  control,
  formState,
  repository,
  useSemverPattern,
}: ConfigureContainerScansType) => {
  const showSemver = repository === REPOSITORIES.DOCKER_HUB || !repository || useSemverPattern;
  const showSemverSwitch = repository === REPOSITORIES.GCR_US || repository === REPOSITORIES.GAR_US;

  return (
    <Stack direction="column">
      <ContainerImageNameField control={control} formState={formState} required />
      <ContainerRepositoryField control={control} formState={formState} required />
      {showSemverSwitch && <UseSemverPatternField control={control} formState={formState} disabled />}
      {showSemver ? (
        <Box marginBottom={1}>
          <MajorVersionsField control={control} formState={formState} required />
          <MinorVersionsField control={control} formState={formState} required />
          <PatchVersionsField control={control} formState={formState} required />
        </Box>
      ) : (
        <Box marginBottom={1}>
          <LastTagsToScanField control={control} formState={formState} required />
        </Box>
      )}
      <AllowedFlavorsField control={control} formState={formState} />
      <BlocklistedFlavorsField control={control} formState={formState} />
      <AutoArchiveField control={control} formState={formState} />
    </Stack>
  );
};
