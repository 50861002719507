import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getSourcesWithSloQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'B',
        url: apiUrl,
        root_selector: 'data.summary.sourcesWithSlo',
        columns: [
          {
            text: 'id',
            selector: 'source.id',
            type: 'string',
          },
          {
            selector: 'source.name',
            text: 'name',
            type: 'string',
          },
          {
            text: 'type',
            selector: 'source.type',
            type: 'string',
          },
          {
            selector: 'source.groups',
            text: 'groups',
            type: 'string',
          },
          {
            text: 'lowestSloRemaining',
            selector: 'lowestSloRemaining',
            type: 'number',
          },
          {
            text: 'critical',
            selector: 'totalCveCounts.critical',
            type: 'number',
          },
          {
            text: 'high',
            selector: 'totalCveCounts.high',
            type: 'number',
          },
          {
            text: 'medium',
            selector: 'totalCveCounts.medium',
            type: 'number',
          },
          {
            text: 'low',
            selector: 'totalCveCounts.low',
            type: 'number',
          },
          {
            text: 'totalSecrets',
            selector: 'totalSecrets',
            type: 'number',
          },
          {
            text: 'totalLicenses',
            selector: 'totalLicenses',
            type: 'number',
          },
        ],
        url_options: {
          body_graphql_query: `
            query getSummary($filters: SummaryFilters) {
              summary(filters: $filters) {
                sourcesWithSlo {
                  source {
                    id
                    name
                    type
                    groups {
                      name
                    }
                  }
                  totalCveCounts {
                    critical
                    high
                    medium
                    low
                  }
                  lowestSloRemaining
                  totalSecrets
                  totalLicenses
                }
              }
            }
          `,
          body_graphql_variables: JSON.stringify({
            filters: {
              groupId: '$group',
              latestOnly: '$latestOnly',
              fromConfigScanOnly: '$fromConfigScanOnly',
            },
          }),
        },
      },
    ])
  );
