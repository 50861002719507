import { css } from '@emotion/css';
import { GrafanaTheme2, renderMarkdown } from '@grafana/data';
import { useStyles2, Card, IconButton } from '@grafana/ui';

import { formatDate } from '../utils';

interface CommentCardType {
  userName: string;
  dateString: string;
  comment: string;
  onDelete: () => void;
  gravatarUrl: string;
}

export const CommentCard = ({ userName, dateString, comment, onDelete, gravatarUrl }: CommentCardType) => {
  const styles = useStyles2(getStyles);

  const capitalizeUserName = userName.charAt(0).toUpperCase() + userName.slice(1);
  const date = formatDate(new Date(dateString));
  const avatar = gravatarUrl || '/avatar/46d229b033af06a191ff2267bca9ae56'; // Default admin gravatar

  return (
    <Card>
      <Card.Heading>
        <span className={styles.secondary}>{capitalizeUserName}</span>
        <span className={styles.secondary}>{date}</span>
      </Card.Heading>
      <Card.Figure>
        <img src={avatar} alt="User avatar" width="40" height="40" />
      </Card.Figure>
      <Card.Description className={styles.primary}>
        <span
          className="markdown-html"
          // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
          dangerouslySetInnerHTML={{ __html: renderMarkdown(comment) }}
        />
      </Card.Description>
      <Card.Actions className={styles.delete}>
        <IconButton name="trash-alt" tooltip="Delete comment" onClick={onDelete} />
      </Card.Actions>
    </Card>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  delete: css({
    justifyContent: 'flex-end',
  }),
  primary: css({
    color: theme.colors.text.primary,
  }),
  secondary: css({
    color: theme.colors.text.secondary,
  }),
});
