import { Stack, Tooltip, Text, Icon, Field, Label, TagsInput } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { ContainerScanningConfig } from '../types';

interface AllowedFlavorsFieldType<T extends ContainerScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const BlocklistedFlavorsField = <T extends ContainerScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: AllowedFlavorsFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Blocklisted Flavors{required ? '*' : ''}</span>
            <Tooltip placement="right-end" content={<Text element="p">Pre-release (i.e. rc, windows)</Text>}>
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'blocklistedFlavors' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <TagsInput
            placeholder="Add flavor (enter key to add)"
            tags={field.value as string[]}
            onChange={(tags) => field.onChange(tags)}
            width={40}
            disabled={disabled}
            invalid={!!formState.errors.blocklistedFlavors}
          />
        )}
      />
    </Field>
  );
};
