import { Stack, Tooltip, Text, Icon, InlineLabel, InlineField, Input } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { ContainerScanningConfig } from '../types';

interface MajorVersionsFieldType<T extends ContainerScanningConfig> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const MajorVersionsField = <T extends ContainerScanningConfig>({
  control,
  formState,
  required,
  disabled,
}: MajorVersionsFieldType<T>) => {
  return (
    <InlineField
      label={
        <InlineLabel width={17}>
          <Stack alignItems="center">
            <span>Major Releases{required ? '*' : ''}</span>
            <Tooltip placement="right-end" content={<Text element="p">How many major versions to scan (ex: 2)</Text>}>
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </InlineLabel>
      }
    >
      <Controller
        name={'majorReleases' as Path<T>}
        rules={{
          required,
          min: {
            value: 0,
            message: 'Must be a positive number',
          },
        }}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value as number}
            type="number"
            width={10}
            invalid={!!formState.errors.majorReleases}
            disabled={disabled}
          />
        )}
      />
    </InlineField>
  );
};
