import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetAlertingConfigQuery,
  GetAlertingConfigQueryVariables,
  GetAllGroupsQuery,
  GetAllGroupsQueryVariables,
  GetGroupsQuery,
  GetGroupsQueryVariables,
  GetScanningConfigQuery,
  GetScanningConfigQueryVariables,
  GetSourceNameQuery,
  GetSourceNameQueryVariables,
  GetSourceVersionsQuery,
  GetSourceVersionsQueryVariables,
} from '__generated__/graphql';

export const GET_GROUPS: TypedDocumentNode<GetGroupsQuery, GetGroupsQueryVariables> = gql`
  query getGroups($id: ID!) {
    source(id: $id) {
      id
      groups {
        id
        name
      }
    }
  }
`;

export const GET_ALL_GROUPS: TypedDocumentNode<GetAllGroupsQuery, GetAllGroupsQueryVariables> = gql`
  query getAllGroups {
    groups {
      id
      name
    }
  }
`;

export const GET_SOURCE_VERSIONS: TypedDocumentNode<GetSourceVersionsQuery, GetSourceVersionsQueryVariables> = gql`
  query getSourceVersions($filters: VersionFilters!) {
    versions(filters: $filters) {
      response {
        id
        version
        isArchived
        scans {
          ingestTime
        }
      }
    }
  }
`;

export const GET_SOURCE_NAME: TypedDocumentNode<GetSourceNameQuery, GetSourceNameQueryVariables> = gql`
  query getSourceName($id: ID!) {
    source(id: $id) {
      id
      name
    }
  }
`;

export const GET_ALERTING_CONFIG: TypedDocumentNode<GetAlertingConfigQuery, GetAlertingConfigQueryVariables> = gql`
  query getAlertingConfig($id: ID!) {
    source(id: $id) {
      id
      alerts {
        id
        slackAlert {
          id
          enabled
          channelName
          severities
        }
        githubIssue {
          id
          enabled
          repositoryName
          createSubIssues
          labels
          assignees
        }
      }
    }
  }
`;

export const GET_SCANNING_CONFIG: TypedDocumentNode<GetScanningConfigQuery, GetScanningConfigQueryVariables> = gql`
  query getScanningConfig($id: ID!) {
    source(id: $id) {
      id
      type
      config {
        ... on ContainerSourceConfig {
          id
          sourceId
          imageName
          repository
          autoArchive
          majorReleases
          minorReleases
          patchReleases
          lastTagsToScan
          useLastNTags
          allowedFlavors
          blocklistedFlavors
        }
        ... on RepositorySourceConfig {
          id
          sourceId
          repositoryName
          autoArchive
          tagPrefix
          containerImages
          lastTagsToScan
          excludedPaths
          scanMain
        }
      }
    }
  }
`;
