import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { SecretCardGridContainer } from 'scenes/components/SecretCard';

interface SecretsState extends SceneObjectState {
  versionId: string;
  category: string;
  severity: string;
  target: string;
  type: string;
  secretFirst: string;
  secretAfter: string;
  simplified: boolean;
}

export class Secrets extends SceneObjectBase<SecretsState> {
  static Component = SecretsRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['versionId', 'category', 'severity', 'target', 'type', 'secretFirst', 'secretAfter'],
  });
}

function SecretsRenderer({ model }: SceneComponentProps<Secrets>) {
  const { versionId, category, severity, target, secretFirst, secretAfter, type, simplified } = model.useState();

  return (
    <SecretCardGridContainer
      versionId={sceneGraph.interpolate(model, versionId)}
      category={sceneGraph.interpolate(model, category)}
      severity={sceneGraph.interpolate(model, severity)}
      target={sceneGraph.interpolate(model, target)}
      type={sceneGraph.interpolate(model, type)}
      first={sceneGraph.interpolate(model, secretFirst)}
      after={sceneGraph.interpolate(model, secretAfter)}
      simplified={simplified}
    />
  );
}
