import { PanelBuilders } from '@grafana/scenes';
import { BarGaugeDisplayMode, ThresholdsMode, VizOrientation } from '@grafana/schema';

export const getVersionSloBarGauge = () =>
  PanelBuilders.bargauge()
    .setTitle(`Version SLO Compliance`)
    .setDisplayMode('transparent')
    .setOption('orientation', VizOrientation.Horizontal)
    .setMin(0)
    .setOption('displayMode', BarGaugeDisplayMode.Basic)
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('versionsWithinSlo')
        .overrideDisplayName('Inside SLO')
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'dark-green', value: 1 },
          ],
        });
      overridesBuilder
        .matchFieldsWithName('versionsOutOfSlo')
        .overrideDisplayName('Out of SLO')
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'dark-green', value: -Infinity },
            { color: 'dark-red', value: 1 },
          ],
        });
    })
    .build();
